import { Injectable, Inject, ElementRef, SecurityContext } from '@angular/core';

import Prism from 'prismjs';
import { HighlightCallback, hooks } from 'prismjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import 'prismjs/components/prism-python.min';
import 'prismjs/components/prism-javascript.min';
import 'prismjs/components/prism-typescript.min';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';
import 'prismjs/plugins/toolbar/prism-toolbar.min';

export type SupportedPrismLanguage = 'python' | 'py' | 'javascript' | 'js' | 'typescript' | 'ts';

export type PrismHighlightOptions = {
  code: string;
  async?: boolean;
  callback?: HighlightCallback;
};

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  private escapeHTML = (code: string) =>
    code.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');

  private trimStartTab = ({ element }: hooks.ElementHighlightedEnvironment) => (element.innerHTML = element.innerHTML.trimStart());

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer
  ) {
    Prism.hooks.add('after-highlight', this.trimStartTab);
  }

  highlightAll() {
    if (isPlatformBrowser(this.platformId)) Prism.highlightAll();
  }

  highlight(el: ElementRef, { code, async, callback }: PrismHighlightOptions): void {
    if (el instanceof ElementRef && el.nativeElement && code !== undefined) {
      el.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, '&#9' + this.escapeHTML(code));
      Prism.highlightElement(el.nativeElement, async, callback);
    }
  }
}
