import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { HostsService } from 'src/app/services/hosts.service';
import { Host } from 'src/app/models/host';
import { IdentityService } from 'src/app/services/identity.service';
import { SchemasService } from 'src/app/services/schemas.service';
import { MatSelectionList } from '@angular/material/list';
import { MatChipInputEvent } from '@angular/material/chips';
import { Feature } from 'src/app/services/feature.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RawService } from 'src/app/services/raw.service';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { DataBuild } from 'src/app/models/data-build';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Project } from 'src/app/models/project';
import { ProjectsService } from 'src/app/services/projects.service';
import { MenuService } from 'src/app/services/menu.service';

export namespace SchemaComponent {
  export type BuildEvent = { build: DataBuild };
  export type DeleteEvent = { teamName: string; rdsName: string };
  export type SaveEvent = {
    rds: {
      name: string;
      adapter: {
        name: string | null;
        paths: string[];
        resolution: number;
        'num-chunks': number;
        hours_filter: string | null;
      };
      testtrain: {
        split: string | null;
        split_percent: number;
        split_date: string;
      }
      output: {
        precache_count: number | null;
        seed: number;
      };
      tags: string[];
      features: string[];
      project: string;
    };
  };
}

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchemaComponent implements OnInit {
  @ViewChild(MatSelectionList, { static: false }) featureSelection: MatSelectionList;
  @ViewChild('featureInput') featureInput: ElementRef<HTMLInputElement>;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() rdsName: string;
  @Input() projectName: string;
  @Input() isNew = false;
  @Output() onBuild = new EventEmitter<SchemaComponent.BuildEvent>();
  @Output() onDelete = new EventEmitter<SchemaComponent.DeleteEvent>();
  @Output() onSave = new EventEmitter<SchemaComponent.SaveEvent>();

  private _builds: DataBuild[] = [];
  private _newBuildStarted: boolean = false;

  adapterForm = new FormGroup({
    rdsName: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    projectName: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    paths: new FormControl<any>('', Validators.required),
    resolution: new FormControl<any>('', Validators.required),
    chunks: new FormControl<any>('', Validators.required),
    hours_filter: new FormControl<any>(''),
    split: new FormControl<any>('', Validators.required),
    split_percent: new FormControl<any>(0),
    split_date: new FormControl<any>(''),
    seed: new FormControl<any>(''),
    precache_count: new FormControl<any>(''),
    tags: new FormControl<any>([]),
    features: new FormControl<any>([])
  });

  projects: Project[] = [];
  selectedProject: Project = null;
  rds: any = null;
  schemas: any = [];
  availableFeatures: any = [];
  features = [];
  newFeature: string;
  selectedFeature: any = null;
  benSelectedFeatureKeys: any[] = [];
  isLoading: boolean = true;
  saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' | 'SAVED' = 'COMPLETE';
  lastSaveTime: string = null;
  tags: Set<string>;
  allFeatures = [];
  filteredFeatures: Observable<string[]>;
  featureCtrl = new FormControl('');
  rawPathsAll: any = [];
  rawPathsFiltered: any = [];
  hosts: Host[] = [];
  nameChange = false;
  preCacheOnly = false;
  buildInProgress = false;
  strict = false;
  dirty = false;

  public get anyBuildsRunning(): boolean {
    return (
      this._newBuildStarted || this._builds.filter((x) => x.isRunning && !x.cancelled && x.errors == 0 && x.isArchived == false).length > 0
    );
  }

  public get mostRecentBuild(): DataBuild {
    return this._builds.filter((x) => x.rdsName == this.rdsName)[0];
  }

  private _filter(value: string): string[] {
    if (!value) return null;

    const filterValue = value.toLowerCase();
    return this.allFeatures.filter((feature) => feature.toLowerCase().includes(filterValue));
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private schemasService: SchemasService,
    private buildsService: DataBuildsService,
    private hostsService: HostsService,
    private identity: IdentityService,
    private rawService: RawService,
    public snackbar: MatSnackBar,
    public featureDialog: MatDialog,
    public allFeaturesDialog: MatDialog,
    public newFeatureDialog: MatDialog,
    private projectsService: ProjectsService,
    private menuService: MenuService
  ) {}

  drop(event: CdkDragDrop<Feature[]>) {
    moveItemInArray(this.features, event.previousIndex, event.currentIndex);
    this.update();
  }

  ngOnInit(): void {
    (<any>window).schema = this;
    if (!this.isWidget) {
      this.menuService.delayedSetActive('schemas');
      this.activatedRoute.params.subscribe((params) => {
        const urlParts = this.router.url.split('/');
        this.teamName = params.team;
        this.projectName = params.project;

        if (urlParts.includes('add')) {
          this.initNew();
        } else {
          this.rdsName = params.rds;
          this.load();
        }
      });
    } else {
      if (this.isNew) this.initNew();
      else this.load();
    }
  }

  selectProject(projectName) {
    this.dirty = false;
    this.selectedProject = this.projects.find((x) => x.name == projectName);
    this.adapterForm.get('projectName').setValue(this.selectedProject.name);
    this.adapterForm.get('paths').setValue(this.selectedProject.raw);
    this.features = this.features.length === 0 ? this.selectedProject.feature : this.features;
    this.featureCtrl.setValue(null);
    this.initFeatures();
  }

  public subcribeToFormChanges() {
    this.adapterForm.valueChanges.pipe(debounceTime(2000)).subscribe((value: any) => {
      const raw = this.adapterForm.getRawValue();
      this.rds.name = raw.rdsName;
      this.rds.project = raw.projectName;
      this.rds.adapter.paths = raw.paths;
      this.rds.adapter.resolution = raw.resolution ? +raw.resolution : null;
      this.rds.adapter.hours_filter = raw.hours_filter ? raw.hours_filter : "all";
      if(!this.rds.testtrain) {
        this.rds.testtrain = {};
      }
      this.rds.testtrain.split = raw.split ? raw.split : "random";
      this.rds.testtrain.split_percent = raw.split_percent ? +raw.split_percent : 20;
      this.rds.testtrain.split_date = raw.split_date || "";
      this.rds.output.seed = raw.seed ? +raw.seed : 0;
      this.rds.output.precache_count = raw.precache_count ? +raw.precache_count : 1;

      this.rds.adapter['num-chunks'] = raw.chunks ? +raw.chunks : null;
      if (!this.rds['output']) {
        this.rds.output = {};
      }
      this.rds.tags = [...this.tags];
      if (this.adapterForm.valid && !this.isNew) this.update();
    });
  }

  public initFeatures() {
    this.allFeatures = this.selectedProject.feature;
    this.filteredFeatures = this.featureCtrl.valueChanges.pipe(
      startWith(null),
      map((feature: string | null) => {
        const retval = feature ? this._filter(feature) : this.allFeatures.slice();
        return retval.filter((x) => !this.features.includes(x));
      })
    );
  }

  public async initNew() {
    this.isNew = true;
    this.isLoading = false;
    this.hosts = await this.hostsService.loadAsync(this.teamName);
    this.loadPaths();

    const schemaName = `schema_${Date.now().toString()}`;

    this.projectName = this.identity.me.selectedProjectName;
    this.adapterForm.setValue({
      rdsName: schemaName,
      projectName: this.projectName ?? '',
      paths: null,
      resolution: 5,
      chunks: 80,
      hours_filter: "all",
      split: "random",
      split_percent: 20,
      split_date: "",
      seed: 0,
      precache_count: 1,
      tags: [],
      features: []
    });

    this.rds = {
      name: schemaName,
      adapter: {
        name: null,
        paths: null,
        resolution: 5,
        'num-chunks': 80,
        hours_filter: "all",
      },
      testtrain: {
        split: "random",
        split_percent: 20,
        split_date: ""
      },
      output: {
        precache_count: null,
        seed: 0,
      },
      tags: [],
      features: []
    };

    this.tags = new Set();
    await this.loadProjects();
    this.initFeatures();
    this.subcribeToFormChanges();
  }

  buildClick() {
    if (!this.mostRecentBuild) return;
    const build: DataBuild = this.mostRecentBuild;
    if (this.isWidget) this.onBuild.emit({ build });
    else this.router.navigate([this.teamName, 'data', 'build', build.rdsName, build.runid, build.user, build.isArchived]);
  }

  public async load() {
    await Promise.all([this.loadProjects(), this.loadPaths(), this.loadSchema()]);
    this.isLoading = false;

    if (this.rds.project) {
      this.selectProject(this.rds.project);
    } else {
      this.selectProject(this.projects[0].name);
      this.rds.project = this.projects[0].name;
    }

    this.initFeatures();
    this.features = this.rds?.features || [];
    this.tags = this.rds?.tags ? new Set(this.rds?.tags) : new Set();

    this.adapterForm.setValue({
      rdsName: this.rds?.name,
      projectName: this.rds?.project,
      paths: this.rds?.adapter?.paths,
      resolution: this.rds?.adapter?.resolution,
      chunks: this.rds?.adapter['num-chunks'],
      hours_filter: this.rds?.adapter?.hours_filter ? this.rds?.adapter?.hours_filter : "all",
      split: this.rds?.testtrain?.split ? this.rds?.testtrain?.split : "random",
      split_percent: this.rds?.testtrain?.split_percent || 20,
      split_date: this.rds?.testtrain?.split_date || "",
      seed: this.rds?.output?.seed || 0,
      precache_count: this.rds?.output?.precache_count || 1,
      tags: this.rds?.tags || [],
      features: this.features
    });

    this.subcribeToFormChanges();

    this.loadBuilds();
  }

  loadBuilds() {
    const sub = this.buildsService.load(this.teamName).subscribe((results: DataBuild[]) => {
      sub.unsubscribe();
      this._builds = results;
      this._builds.sort((a, b) => {
        return +b.runid - +a.runid;
      });
      this.buildInProgress =
        this._newBuildStarted ||
        (this.mostRecentBuild?.isRunning &&
          !this.mostRecentBuild.cancelled &&
          this.mostRecentBuild.errors == 0 &&
          this.mostRecentBuild.isArchived == false);
      if (this.buildInProgress) {
        this.adapterForm.disable();
      }
    });
  }

  async loadPaths(): Promise<void> {
    const rawData: any = await this.rawService.getAsync(this.teamName);
    this.rawPathsAll = rawData.map((x) => x.fullPath);
    this.rawPathsFiltered = this.rawPathsAll;
  }

  async loadProjects(): Promise<void> {
    this.projects = (await this.projectsService.loadAsync(this.teamName)) as any;
    if (this.isNew) {
      if (!this.projectName) this.selectProject(this.projects[0].name); //TODO: error here when no project is selected
      else this.selectProject(this.projectName);
    }
  }

  async loadSchema(): Promise<void> {
    this.rds = await this.schemasService.getByNameAsync(this.teamName, this.rdsName);
  }

  onProjectChange(event): void {
    if (this.dirty) {
      if (!confirm('Any selections you have made will be overwritten. Do you wish to select a new project?')) {
        this.adapterForm.get('projectName').setValue(this.selectedProject.name);
        return;
      }
    }

    this.selectProject(event.value);
  }

  onPathsChange(_event): void {
    this.dirty = true;
  }

  async addFeature(event: MatAutocompleteSelectedEvent): Promise<void> {
    this.dirty = true;
    let featureName: string = null;

    featureName = event.option.viewValue;

    if (featureName) {
      this.features.push(featureName);
      if (!this.isNew) this.update();
    }

    this.featureInput.nativeElement.value = '';
    this.featureCtrl.setValue(null);
  }

  deleteFeature(feature): void {
    this.features = this.features.filter((x) => x !== feature);
    if (!this.isNew) this.update();
    this.featureCtrl.setValue(null);
  }

  async update(): Promise<void> {
    this.saveStatus = 'SAVING';

    this.rds.project = this.selectedProject.name;
    this.rds.features = this.features;
    this.rds.adapter.paths = this.adapterForm.getRawValue().paths;

    if (this.rdsName !== undefined && this.rds.name !== this.rdsName) {
      this.nameChange = true;
      await this.schemasService.deleteAsync(this.teamName, this.rdsName);
    }

    try {
      await this.schemasService.postAsync(this.teamName, this.rds.name, this.rds).then(console.log);
      this.saveStatus = 'SAVED';
      this.lastSaveTime = new Date().toLocaleString();

      setTimeout(() => {
        if (this.saveStatus === 'SAVED') this.saveStatus = 'COMPLETE';
      }, 2000);

      if (this.isNew || this.nameChange) {
        if (!this.isWidget) window.location.href = `${ConfigService.hostUrl}/${this.teamName}/data/schema/${this.rds.name}`;
        else this.widgetUpdate();
      }
    } catch (error) {
      this.saveStatus = 'ERROR';
    }
  }

  widgetUpdate(): void {
    this.onSave.emit({ rds: this.rds });
    this.saveStatus = 'COMPLETE';
    this.isNew = false;
  }

  retry(): void {
    this.update();
  }

  addTag(event: MatChipInputEvent): void {
    if (event.value) {
      this.tags.add(event.value);
      this.rds.tags = [...this.tags];
      event.chipInput!.clear();
      this.adapterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  removeTag(keyword: string): void {
    this.tags.delete(keyword);
    this.rds.tags = [...this.tags];
    this.adapterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  applyFilter(filterValue: string): void {
    if (!filterValue || filterValue.length === 0) {
      this.rawPathsFiltered = this.rawPathsAll;
      return;
    }

    this.rawPathsFiltered = this.rawPathsAll.filter((s) => s.includes(filterValue));
  }

  save(): void {
    this.update();
  }

  async delete(): Promise<void> {
    if (!confirm('Are you sure you want to delete this schema?')) {
      return;
    }

    try {
      await this.schemasService.deleteAsync(this.teamName, this.rdsName);
      if (this.isWidget) this.onDelete.emit({ teamName: this.teamName, rdsName: this.rdsName });
      else this.router.navigate(['/', this.teamName, 'data', 'schemas']);
    } catch (e) {
      console.error(e);
      this.openSnackBar('An error occured. See logs for details.', 'OK');
    }
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  async clean() {
    this._newBuildStarted = true;
    const sub = this.buildsService.clean(this.teamName, this.rdsName).subscribe((results: any) => {
      sub.unsubscribe();
      this._newBuildStarted = false;
    });
  }

  async launch() {
    this.adapterForm.disable();
    const sub = this.buildsService.start(this.teamName, this.rdsName, this.preCacheOnly, this.strict).subscribe((results: any) => {
      sub.unsubscribe();
      this._newBuildStarted = true;
      this.loadBuilds();
    });
  }
}
