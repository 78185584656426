import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Project } from 'src/app/models/project';
import { Run } from 'src/app/models/run';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { PerfService } from 'src/app/services/perf.service';
import { VaultService } from 'src/app/services/vault.service';

@Component({
  selector: 'app-vault-selector',
  templateUrl: './vault-selector.component.html',
  styleUrls: ['./vault-selector.component.scss']
})
export class VaultSelectorComponent implements OnInit, OnChanges {
  @Input() teamName: string;
  @Input() project: Project;
  @Output() onOpenExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onAddToVault = new EventEmitter();

  public modelSortMetric: string = 'min_val_loss';
  public prunedModelSortMetric: string = 'val_loss';
  public projectExperiments: Run[] = [];
  public runningExperimentCount: number = 0;

  public get modelMetrics(): string[] {
    if (this.projectExperiments.length == 0) {
      return [];
    }
    const negativeMetrics = ['loss', 'val_loss'];
    const filter = (x) =>
      (x.startsWith('min_') && negativeMetrics.includes(x.substring(4))) ||
      (x.startsWith('max_') && !negativeMetrics.includes(x.substring(4)));
    return Object.keys(this.projectExperiments[this.projectExperiments.length - 1]).filter(filter);
  }

  private topModels: any[] = [];
  private selectedEpoch: any[] = [0, 0, 0];

  public areMetricsLoading = false;
  public displayedColumns = ['run', 'epoch', 'val_loss', 'val_precision', 'val_recall', 'vault'];
  public topModelsDS = new MatTableDataSource<any>([]);

  constructor(
    private _perfService: PerfService,
    private _experimentsService: ExperimentsService,
    private _vaultService: VaultService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    this.init();
  }

  init() {
    const sub = this._experimentsService.load(this.teamName).subscribe((data: any) => {
      sub.unsubscribe();

      const experiments = data.runs;
      experiments.forEach((x) => {
        const d = new Date(0, 0, 0, 0, 0, 0, 0);
        d.setSeconds(x.elapsed);
        x['formattedElapsed'] = d;
      });

      this.projectExperiments = experiments.filter((x) => x.project == this.project?.name);
      this.runningExperimentCount = this.projectExperiments?.filter((x) => x.is_running).length;

      this.loadModelMetrics();
    });
  }
  async selectModelMetric($event) {
    this.prunedModelSortMetric =
      this.modelSortMetric.startsWith('min') || this.modelSortMetric.startsWith('max')
        ? this.modelSortMetric.substring(4)
        : this.modelSortMetric;

    this.loadModelMetrics();
  }

  private getTopModelEpoch(index) {
    const epoch = this.selectedEpoch[index];
    return this.topModels[index].data.find((x) => x.epoch == epoch);
  }

  loadModelMetrics() {
    let sortFunc = this.modelSortMetric.startsWith('min')
      ? (a, b) => a[this.prunedModelSortMetric] - b[this.prunedModelSortMetric]
      : (b, a) => a[this.prunedModelSortMetric] - b[this.prunedModelSortMetric];

    let topRuns = this.projectExperiments
      .sort(sortFunc)
      .slice(0, 3)
      .map((x) => x.runid);

    this.areMetricsLoading = true;
    setTimeout(() => {
      this.topModels = [];
      this.topModelsDS = new MatTableDataSource<any>([]);
      this.selectedEpoch = [];

      const sub = this._perfService.getRuns(this.teamName, this.project.name, topRuns).subscribe((results: any[]) => {
        sub.unsubscribe();
        for (let item of results) {
          item.data = item.data.sort(sortFunc);
          this.selectedEpoch.push(item.data[0]?.epoch);
          item.epoch = 0;
          this.topModels.push(item);
        }

        this.topModelsDS = new MatTableDataSource<any>(this.topModels);
        this.areMetricsLoading = false;
      });
    }, 100);
  }

  $clickOpenExperiments() {
    this.onOpenExperiments.emit({ projectName: this.project.name });
  }

  async updateVaultName($event, index, runid, epoch) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!$event.srcElement.value || $event.srcElement.value.trim().length === 0) {
      return;
    }

    const model = { name: $event.srcElement.value, project: this.project.name, runid, epoch };

    const sub = this._vaultService.add(this.teamName, model).subscribe(({ message, vaultItem }) => {
      sub.unsubscribe();

      if (message === 'model added to vault') {
        this.getTopModelEpoch(index).vault = vaultItem.name;
        this.onAddToVault.emit();
      }
    });
  }
}
