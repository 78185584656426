import { Injectable } from '@angular/core';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

export type RelocateRawParameters = {
  hostName: string;
  src: string;
  dst: string;
};

export type DeleteRawParameters = {
  hostName: string;
  path: string;
};

@Injectable({
  providedIn: 'root'
})
export class RawService {
  private _item$: Subject<any[]> = new Subject();

  constructor(private http: HttpClient) {
    window.$raw = this;
  }

  public get(teamName: string): Observable<any[]> {
    this.http.get(`${ConfigService.apiUrl}/data/raw/${teamName}`).subscribe((response: any[]) => {
      this._item$.next(response);
    });

    return this._item$.asObservable();
  }

  public getAsync = (teamName: string) => lastValueFrom(this.http.get<any[]>(`${ConfigService.apiUrl}/data/raw/${teamName}`));

  public relocate(teamName: string, mode: 'copy' | 'move', files: RelocateRawParameters[]) {
    this.http
      .patch(`${ConfigService.apiUrl}/data/raw/${teamName}/relocate`, {
        copy: mode === 'copy',
        files
      })
      .subscribe((response: { status: string[]; files: any[] }) => {
        console.log(response);
        this._item$.next(response.files);
      });

    return this._item$.asObservable();
  }

  public delete(teamName: string, files: DeleteRawParameters[]): Observable<any[]> {
    this.http
      .delete(`${ConfigService.apiUrl}/data/raw/${teamName}`, {
        body: { files }
      })
      .subscribe((response: { status: string[]; files: any[] }) => {
        console.log(response);
        this._item$.next(response.files);
      });

    return this._item$.asObservable();
  }

  public count(teamName: string, asset: string, file: string): Promise<number | undefined> {
    return lastValueFrom(this.http.get<number | undefined>(`${ConfigService.apiUrl}/data/raw/count/${teamName}/${asset}/${file}`));
  }
}
