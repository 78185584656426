import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RawService } from 'src/app/services/raw.service';
import { UploadService } from 'src/app/services/upload.service';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { MoveRawDialogComponent, MoveRawDialogData, MoveRawDialogReturn } from '../../move-raw-dialog/move-raw-dialog.component';
import { CacheFileService } from 'src/app/services/cache-file.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ProjectsService } from 'src/app/services/projects.service';
import { Project } from 'src/app/models/project';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MenuService } from 'src/app/services/menu.service';
import { TerminalComponent } from '../../terminal/terminal.component';
import { IdentityService } from 'src/app/services/identity.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.scss']
})
export class RawComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SplitComponent) split: SplitComponent;
  @ViewChild(MatMenuTrigger) private trigger: MatMenuTrigger;
  @ViewChild('contextMenuTrigger') _contextMenu: MatMenuTrigger;

  @ViewChild(MatSelect) projectSelect: MatSelect;
  @ViewChild(TerminalComponent) terminal: TerminalComponent;

  public contextMenuPosition = { x: '0px', y: '0px' };

  @Input() isWidget: boolean = false;
  @Output() onViewData = new EventEmitter<{ path: string; fileType: string }>();

  public isLoading = true;
  public pageSize = 10;
  public displayedColumns: string[] = ['select', 'fileName', 'view', 'fileTime', 'folder', 'owner', 'fileSize', 'cacheFile'];
  public fileTypes: string[] = ['csv', 'h5', 'txt', 'gz', 'zip'];
  public selectedFileType: string;
  public rawData = [];
  public dataSource = new MatTableDataSource<any>([]);
  public uploadProgress: any[] = [];
  public uploadComplete: boolean = false;
  public view: string = 'TABLE';
  public screenInfo: any = null;
  public logInfo: any = null;
  public menuPosition = { x: '0px', y: '0px' };
  public selection = new SelectionModel<any>(true, []);
  private teamName: string;
  public projects: Project[] = [];
  public saving = false;
  private _subs = new SubscriptionContainer();
  public isPolling: boolean = false;
  public lastUpdated: Date;
  public terminalId = new Date().getTime().toString();
  private _pollingTimer?: number;

  public get hasSelected(): boolean {
    return this.selection.selected.length !== 0;
  }

  private $loadNewRawData(data: any[]) {
    this.isLoading = false;
    this.rawData = data;
    this.dataSource.data = this.rawData;
    if (this.selection) {
      this.selection.clear();
    }

    this.identity.me.selectedProject?.raw?.forEach((x) => this.selection.select(this.dataSource.data.find((y) => y.fullPath == x)));

    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.optimizePageSize();
    }, 0);
  }

  public onContextMenu(event: MouseEvent, item): void {

    this.contextMenuPosition.x = (event.clientX - this._elRef.nativeElement.getBoundingClientRect().left) + 'px';
    this.contextMenuPosition.y = (event.clientY  - this._elRef.nativeElement.getBoundingClientRect().top + 20) + 'px';

    this._contextMenu.menuData = { item };
    this._contextMenu.menu.focusFirstItem('mouse');
    this._contextMenu.openMenu();
  }

  private openDialog(mode: 'copy' | 'move', raw: any): Promise<MoveRawDialogReturn> {
    return new Promise((resolve, reject) => {
      try {
        const ref = this.dialog
          .open<MoveRawDialogComponent, MoveRawDialogData, MoveRawDialogReturn>(MoveRawDialogComponent, {
            minWidth: '500px',
            data: { mode, raws: raw, teamName: this.teamName }
          })
          .afterClosed()
          .subscribe((path) => {
            ref.unsubscribe();
            resolve(path);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rawService: RawService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private cacheFileService: CacheFileService,
    private projectsService: ProjectsService,
    private menuService: MenuService,
    private identity: IdentityService,
    private _settings: SettingsService,
    private _elRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this._subs.add = this.activatedRoute.params.subscribe((params) => {
      this.load(params.team);
    });

    this._subs.add = this._settings.pollingRate$.subscribe(this.$changePollingRate.bind(this));
  }

  ngOnInit(): void {
    (<any>window).raw = this;
    addEventListener('dragenter', (event) => {
      if (this.uploadService.dialog.openDialogs.length == 0) this.openFileUpload();
    });
    this.menuService.delayedSetActive('raw');

    this.identity.projectChange$.subscribe((_projectName) => {
      this.selectProject();
    });

    this.$changePollingRate(this._settings.pollingRate);
  }

  ngOnDestroy(): void {
    this._subs.dispose();
    if (this._pollingTimer) clearTimeout(this._pollingTimer);
  }

  optimizePageSize() {
    const parentHeight: number = (<any>document.querySelector('app-raw')).offsetHeight;
    var x = parentHeight;

    this.pageSize = Math.round(0.02 * x) - 5;
    if (this.paginator) {
      this.paginator._changePageSize(this.pageSize);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  load(teamName: string) {
    this.teamName = teamName;
    const sub = this.rawService.get(teamName).subscribe((response: any[]) => {
      sub.unsubscribe();
      this.isLoading = false;
      this.lastUpdated = new Date();
      this.$loadNewRawData(response);
    });
  }

  public async refreshCacheData(): Promise<void> {
    this.isPolling = true;
    const rawData = await this.rawService.getAsync(this.teamName);
    const cacheData = Object.fromEntries(
      rawData.map(({ fullPath, hasCacheError, hasCacheFile }) => [fullPath, { hasCacheError, hasCacheFile }])
    );

    for (const rds of this.rawData) {
      if (rds.fullPath in cacheData) Object.assign(rds, cacheData[rds.fullPath]);
    }

    this.lastUpdated = new Date();
    this.isPolling = false;
  }

  selectProject() {
    this.selection.clear();
    this.identity.me.selectedProject?.raw?.forEach((x) => this.selection.select(this.dataSource.data.find((y) => y.fullPath == x)));
  }

  async toggleSelection(row) {
    this.selection.toggle(row);
    if (!this.identity.me.selectedProject) return;

    var added = this.selection.selected.find((x) => x?.fullPath == row.fullPath) != null;
    var found = this.identity.me.selectedProject;

    if (added) {
      if (!found.raw) found.raw = [];
      found.raw.push(row.fullPath);
      found.raw.sort();
    } else {
      var index = found.raw.findIndex((x) => x == row.fullPath);
      found.raw.splice(index, 1);
    }
    this.identity.setProject(found);
    await this.projectsService.updateRaw(this.teamName, this.identity.me.selectedProject.name, row.fullPath, added);
  }

  closeCacheView() {
    this.view = 'TABLE';
  }

  explore() {
    this.router.navigate([this.teamName, 'data', 'explore', this.identity.me.selectedProject?.name]);
  }

  refresh() {
    this.isLoading = true;
    this.load(this.teamName);
  }

  unselectAll() {
    this.dataSource.filteredData.forEach((row: any) => {
      if (this.selection.selected.find((x) => x.fullPath == row.fullPath)) this.toggleSelection(row);
    });
  }

  selectAll() {
    this.dataSource.filteredData.forEach((row: any) => {
      if (!this.selection.selected.find((x) => x.fullPath == row.fullPath)) this.toggleSelection(row);
    });
  }

  onFileTypeSelect(event: any) {
    if (!this.selectedFileType) {
      this.dataSource.data = this.rawData;
      return;
    }

    this.dataSource.data = this.rawData.filter((x) => x.fileName.includes(event.value));
  }

  async openFileUpload() {
    const results = await this.uploadService.promptForUpload(this.teamName);
    if (results && results.reload) {
      this.isLoading = true;
      this.load(this.teamName);
    }
  }

  async onViewCacheFile($ev: PointerEvent, row) {
    $ev.stopPropagation();

    this.view = 'CACHE';
    this.logInfo = null;
    this.screenInfo = { id: row.cacheFileScreen };
  }

  async onViewCacheLog($ev: PointerEvent, row) {
    $ev.stopPropagation();
    this.view = 'CACHE';
    if (this.screenInfo != null) this.terminal.disconnect();
    this.screenInfo = null;
    const path = `${row.folder}/.adapter/create_cachefile_${row.fileName.split('.')[0]}.${row.fileName.split('.')[1]}.txt`;
    this.logInfo = { path };
  }

  $createCacheClick(items = []) {
    if(items.length == 0) {
      items = this.selection.selected;
    }
    for (const raw of items) {
      if (!raw) continue;

      if (raw.hasCacheFile || raw.cacheFileScreen) {
        console.warn(`Skipping ${raw.fullPath}. Cache file already exists.`);
        continue;
      }

      this.cacheFileService.createCacheFileAsync(this.teamName, raw.fullPath);
    }

    setTimeout(() => {
      this.load(this.teamName);
    }, 100);
  }

  $relocateClick(mode: 'move' | 'copy') {
    this.isLoading = true;
    this.openDialog(mode, this.selection.selected).then((response) => {
      if (!response) {
        this.isLoading = false;
        return;
      }

      const sub = this.rawService.relocate(this.teamName, mode, response).subscribe((response) => {
        sub.unsubscribe();
        this.$loadNewRawData(response);
      });
    });
  }

  $deleteClick() {
    if (!confirm('Are you sure you want to delete these raw data files?')) return;

    this.isLoading = true;
    const sub = this.rawService
      .delete(
        this.teamName,
        this.selection.selected.map(({ host: hostName, fullPath: path }) => ({ hostName, path }))
      )
      .subscribe((response) => {
        sub.unsubscribe();
        this.$loadNewRawData(response);
      });
  }

  $viewClick($event, row) {
    $event.stopPropagation();
    if (!row.hasCacheFile && row.fullPath.includes('.csv')) {
      alert('Please create a cache file before viewing this asset.');
      return;
    }

    if (this.isWidget) {
      this.onViewData.emit({ path: row.fullPath, fileType: row.fullPath.split('/').pop().split('.')[1] });
    } else {
      this.router.navigate([this.teamName, 'data', 'data-viewer', encodeURI(row.fullPath.replace(/\//g, '\\'))]);
    }
  }

  public $changePollingRate(newRate: number): void {
    if (this._pollingTimer) clearInterval(this._pollingTimer);
    this._pollingTimer = setInterval(this.refreshCacheData.bind(this), newRate) as unknown as number;
  }
}
