import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableFeature, Feature, FeatureService } from 'src/app/services/feature.service';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { Meta } from 'src/app/models/meta';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RawService } from 'src/app/services/raw.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ProjectsService } from 'src/app/services/projects.service';
import { Project } from 'src/app/models/project';
import { MatSelectionList } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DataViewerComponent } from '../data-viewer/data-viewer.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MenuService } from 'src/app/services/menu.service';
import { MatTabGroup } from '@angular/material/tabs';

import { IdentityService } from 'src/app/services/identity.service';
import { minMax } from 'src/app/directives/iterable.util';
import { ExploreChartComponent } from '../../explore-chart/explore-chart.component';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit, AfterViewInit {
  @ViewChild('dynamicForm') dynamicForm?: DynamicFormComponent;
  @ViewChild('metaPanel') metaPanel: MatExpansionPanel;
  @ViewChild('parameterPanel') parameterPanel: MatExpansionPanel;
  @ViewChild('demoPanel') demoPanel: MatExpansionPanel;
  @ViewChild('featureTabControl', { static: false }) featureTabControl: MatTabGroup;
  @ViewChild('exploreChart') chart: ExploreChartComponent;
  @ViewChild('rawFileList') rawFileList: MatSelectionList;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DataViewerComponent) dataViewer: DataViewerComponent;

  @Output() onEditFile = new EventEmitter<{ path: any }>();
  @Output() onPacketSelect = new EventEmitter<{ packetContent: any }>();

  @HostListener('document:keydown', ['$event'])
  public keydownEvent($ev: KeyboardEvent) {
    if ($ev.code === 'Escape') {
      if (this.editMode) {
        this.$clickReset();
      }

      if (this.feature.isScript) {
        this.newFeatureName = null;
      }

      this.showFlyout = false;
    }
  }

  private _testCache = [];
  private _estimates = [];
  private estimateAvg = null;
  private _isEstimating = false;
  private _stopEstimating = false;
  private _loading = {
    features: false,
    meta: false,
    indices: false,
    demo: false
  };

  public hiddenFeatureList = new SelectionModel<any>(true, []);
  public featureScriptDS = new MatTableDataSource<AvailableFeature>([]);
  public featureScriptDisplayedColumns: string[] = ['script'];
  public featureDS = new MatTableDataSource<Feature>([]);
  public featureDisplayedColumns: string[] = ['select', 'name'];
  public newFeatureName: string = '';
  public view = 'TABLE';
  public showFlyout: boolean = null;
  public code: string;
  public csChartView = false;
  public pageSize = 10;
  public feature: Feature = { name: '', path: '' };
  public features: Feature[] = [];
  public teamName: string;
  public projectName: string;
  public meta: Meta;
  public hasMeta: boolean = false;
  public sampleCount: number = 0;
  public sample_timestamp: string = null;
  public projects: Project[] = [];
  public availableFeatures: AvailableFeature[] | undefined;
  public allRawFiles: any[] | undefined;
  public selectedRawFile: any | undefined;
  public demoResults: number[] | number[][] | 'empty' | 'error' | undefined;
  public demoTableColumns: string[] | undefined;
  public demoTableData: any | undefined;
  public demoTableDS = new MatTableDataSource<any>([]);
  public selectedFeature: any = null;
  public originalName: string = null;
  public editMode: boolean = false;
  public visibleMinX: number;
  public visibleMaxX: number;
  public metaForm = this.formBuilder.group({
    feature: new FormControl('', Validators.required),
    name: new FormControl('', [
      Validators.required,
      ({ value }: AbstractControl<string>) =>
        this.features.map((x) => x.name.toLowerCase()).includes(value.toLowerCase()) ? { usedName: { value } } : null
    ])
  });
  public lastSelection = null;
  public metaCache = [];
  public xValues: number[];
  public openValues: number[];
  public highValues: number[];
  public lowValues: number[];
  public closeValues: number[];
  public volumeValues: number[];
  public featureGroups = [];
  public featuresByGroup: Record<string, Feature[]> = {};
  public newGroup: string = '';

  public get index(): number {
    return this.chart.index ?? null;
  }

  public get loading() {
    return Object.values(this._loading).reduce((anyLoading, isLoading) => anyLoading || isLoading, false);
  }

  public get demoPanelDisabled() {
    return this.allRawFiles === undefined || this.meta === undefined || (this.dynamicForm?.form?.invalid ?? true);
  }

  public get isLabel(): boolean {
    return this.meta.returns.includes('label');
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private rawService: RawService,
    private projectsService: ProjectsService,
    public snackbar: MatSnackBar,
    public menuService: MenuService,
    private identity: IdentityService
  ) {
    (<any>window).explore = this;
  }

  public async ngAfterViewInit(): Promise<void> {
    this.featureScriptDS.data = await this.featureService.getAvailableAsync(this.teamName);
    this.featureDS.data = await this.featureService.getAllAsync(this.teamName);
    this.updateFeatureGroups();

    this.selectProject(this.identity.me?.selectedProject?.name);

    const sub = this.rawService.get(this.teamName).subscribe((response: any[]) => {
      sub.unsubscribe();
      this.$loadNewRawData(response);
    });

    this.$clickTest();
  }

  public ngOnInit(): void {
    this.menuService.delayedSetActive('features');

    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.projectName = params.project;
    });

    this.identity.projectChange$.subscribe((projectName) => {
      this.selectProject(projectName);
    });
  }

  onPacketSelectInner(packetContent: any) {
    this.onPacketSelect.emit({ packetContent: packetContent.packetContent });
  }

  editFile(feature: Feature) {
    var path = feature.path;
    this.onEditFile.emit({ path });
  }

  sidebarSize = 20;
  chartXSize = 80;
  chartYSize = 70;
  featureSize = 30;
  chartMax = false;
  toggleChartMax() {
    if(this.chartMax) {
      this.sidebarSize = 20;
      this.chartXSize = 80;
      this.chartYSize = 70;
      this.featureSize = 30;
      this.chartMax = false;
    }
    else {
      this.sidebarSize = 0;
      this.chartXSize = 100;
      this.chartYSize = 100;
      this.featureSize = 0;
      this.chartMax = true;
    }
  }

  public updateFeatureGroups(): void {
    this.featureGroups = [];
    this.featuresByGroup = {};

    this.featureDS.data.forEach((feat) => {
      const group = feat.group ?? 'Ungrouped';
      if (!this.featureGroups.includes(group)) this.featureGroups.push(group);
      if (!Object.keys(this.featuresByGroup).includes(group)) this.featuresByGroup[group] = [feat];
      else this.featuresByGroup[group].push(feat);
    });
  }

  private $loadNewRawData(data: any[]): void {
    this.allRawFiles = data;
  }

  public onProjectChange(event): void {
    this.selectProject(event.value.name);
  }

  public selectProject(projectName): void {
    this.projectName = projectName;
    this.updateFeatureSelections();
  }

  public updateFeatureSelections(): void {
    this.hiddenFeatureList.clear();
    this.hiddenFeatureList.select(...this.featureDS.data.filter((x) => this.identity.me.selectedProject?.feature?.includes(x.name)));

    const featuresThatNoLongerExist = this.identity.me.selectedProject?.feature?.filter(
      (x) => !this.featureDS.data.find((y) => x === y.name)
    );
    if (featuresThatNoLongerExist?.length > 0) {
      this.identity.me.selectedProject.feature = this.identity.me.selectedProject.feature.filter((x) =>
        this.featureDS.data.find((y) => x === y.name)
      );
      console.log('Removing features from project that no longer exist.', featuresThatNoLongerExist);
      featuresThatNoLongerExist.forEach(async (featureName) => {
        await this.projectsService.updateFeatures(this.teamName, this.identity.me.selectedProject.name, featureName, false);
      });
    }
  }

  public composeSchema(): void {
    this.router.navigate([this.teamName, 'data', 'schema', 'add', this.projectName]);
  }

  public optimizePageSize(): void {
    if (!this.table) return;
    const tableElement = (<any>this.table)._elementRef.nativeElement;
    var x = window.innerHeight - tableElement.offsetTop;
    var blocks = Math.round(0.00493601 * x - 1.5);
    this.pageSize = blocks * 5;
    this.paginator._changePageSize(this.pageSize);
    this.demoTableDS.paginator = this.paginator;
  }

  public getVisibleYValues(data, visibleMinX, visibleMaxX): { visibleMinY: number; visibleMaxY: number } {
    const yValues = data.filter((x) => x[0] >= visibleMinX && x[0] <= visibleMaxX).map((y) => y[1]);
    let [visibleMinY, visibleMaxY] = minMax(...yValues);

    //add some padding
    const diff = visibleMaxY - visibleMinY;
    visibleMinY = visibleMinY - diff;
    visibleMaxY = visibleMaxY + diff;

    return { visibleMinY, visibleMaxY };
  }

  public async clickFeatureScript(featureScript): Promise<void> {
    // toggle
    if (featureScript == this.lastSelection) {
      this.showFlyout = !this.showFlyout;
      return;
    }

    // every time a feature-script is clicked, show it (unless we're toggling)
    this.showFlyout = true;

    this.selectedFeature = featureScript;
    if (this.selectedFeature.script) {
      this.selectedFeature.name = this.selectedFeature.script;
    }

    this.setTableData(undefined);
    await this.loadMeta(featureScript.path);
    this.lastSelection = featureScript;
  }

  public async clickFeature(event: MouseEvent, feature): Promise<void> {
    if (feature == this.lastSelection) {
      this.showFlyout = !this.showFlyout;
      return;
    }

    // second time a feature has been clicked
    if (this.feature?.name?.length == 0 && this.selectedFeature !== null && this.selectedFeature.name === feature.name)
      this.showFlyout = true;

    this.selectedFeature = feature;
    this.newGroup = feature?.group ?? null;
    this.setTableData(undefined);
    var defaultValues = { ...feature };
    ['host', 'name', 'path', 'script'].forEach((x) => delete defaultValues[x]);

    await this.loadMeta(feature.path, defaultValues);
    this.$clickTest();
    this.lastSelection = feature;
    const y = event.y > window.innerHeight / 2 ? 100 : event.y;
    //(document.querySelector('#flyout') as HTMLElement).style.top = y - 60 + 'px';
  }

  public closeFlyout(): void {
    this.showFlyout = false;
  }

  public async clickFeatureCheckbox(event: MatCheckboxChange, feature): Promise<void> {
    this.hiddenFeatureList.toggle(feature);

    const added = event.checked;
    const found = this.identity.me.selectedProject;
    if (added) {
      if (!found.feature) found.feature = [];
      found.feature.push(feature.name);
      found.feature.sort();
    } else {
      const index = found.feature.findIndex((x) => x == feature.name);
      found.feature.splice(index, 1);
    }
    this.identity.updateProject(found);
    await this.projectsService.updateFeatures(this.teamName, this.identity.me.selectedProject.name, feature.name, added);
  }

  public $clickEdit(): void {
    this.editMode = true;
    this.originalName = this.selectedFeature.name;
  }

  public $clickRename(): void {
    this.editMode = false;

    if (this.selectedFeature.name === this.originalName) {
      return;
    }

    this.featureService.renameAsync(this.teamName, this.originalName, this.selectedFeature.name).then((results: any) => {
      if (results.status === 'error') {
        this.selectedFeature.name = this.originalName;
        this.openSnackBar(results.message, 'OK');
      }
    });
  }

  public $clickReset(): void {
    this.editMode = false;
    this.selectedFeature.name = this.originalName;
  }

  public $clickClone(): void {
    this.feature.isScript = true;
  }

  public $clickDelete(): void {
    const target = this.selectedFeature;
    if (!confirm(`Are you sure you want to delete feature ${target.name}?`)) return;
    this.featureService.deleteAsync(this.teamName, target.name).then(() => {
      this.hasMeta = false;
      this.meta = undefined;
      this.featureService.getAllAsync(this.teamName).then((value) => {
        this.featureDS.data = value;
        this.updateFeatureSelections();
        this.updateFeatureGroups();
        this.openSnackBar('Feature removed.', 'OK');
      });
    });
  }

  public $clickRegroup(): void {
    this.featureService.regroupAsync(this.teamName, this.selectedFeature.name, this.newGroup).then(({ feature }: any) => {
      this.selectedFeature.group = feature.group;
      this.closeFlyout();
      this.newGroup = feature.group;
      this.updateFeatureGroups();
    });
  }

  public $cancelRegroup(): void {
    this.newGroup = this.selectedFeature.group;
  }

  public $clickSave(): void {
    this.feature.name = this.newFeatureName;
    if (this.newGroup !== '') this.feature.group = this.newGroup;
    var newFeature = { ...this.feature };
    delete newFeature.isScript;

    this.featureService
      .addAsync(this.teamName, newFeature)
      .then(() => {
        this.featureService.getAllAsync(this.teamName).then((value) => {
          this.featureDS.data = value;
          this.updateFeatureSelections();
          this.updateFeatureGroups();
          this.openSnackBar('New feature saved.', 'OK');
          this.newFeatureName = null;
          this.newGroup = '';
          this.selectedFeature = newFeature;
          this.feature.isScript = false;
          this.featureTabControl.selectedIndex = 0;
        });
      })
      .catch((e) => {
        console.warn('Failed to save!', { e });
      });
  }

  public openSnackBar(message: string, action: string): void {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  public async loadMeta(featureScriptPath, defaultValues = null): Promise<void> {
    this._loading.meta = true;
    this.hasMeta = false;
    this.meta = undefined;

    const key = JSON.stringify([this.teamName, featureScriptPath]);
    const foundInCache = this.metaCache.findIndex((x) => x.key === key);
    var meta = null;
    if (foundInCache != -1) {
      meta = this.metaCache[foundInCache].data;
    } else {
      meta = await this.featureService.reflectAsync(this.teamName, featureScriptPath);
      this.metaCache.push({ key: key, data: meta });
      if (this.metaCache.length > 3) this.metaCache.shift();
    }

    this.feature = {
      name: 'blank',
      path: featureScriptPath,
      isScript: defaultValues == null,
      ...Object.fromEntries(meta.params.map(({ name }) => [name, undefined]))
    };

    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        if (Object.keys(this.feature).includes(key)) {
          this.feature[key] = defaultValues[key];
        }
      });
    }

    this.meta = meta;
    this.hasMeta = true;
    this._loading.meta = false;
  }

  public async setTableData(demoResults?: any[] | any[][]): Promise<void> {
    if (demoResults === undefined) {
      // Clear any previous demo results
      Object.assign(this, { demoResults: undefined, demoTableColumns: undefined, demoTableData: undefined });
    } else if (demoResults.length === 0) {
      // Mark the results as empty, which displays a message
      Object.assign(this, { demoResults: 'empty', demoTableColumns: undefined, demoTableData: undefined });
    } else {
      const rowToObject = (row, rowNum = 0) => ({
        '#': rowNum + 1,
        ...Object.fromEntries(row.map((e, i) => [this.demoTableColumns[i + 1], e]))
      });

      this.demoResults = demoResults;

      // Resolve 2D data [[...],] vs 1D data [...]
      const is2D = demoResults[0] instanceof Array;
      const numberResultColumns = is2D ? demoResults[0].length : demoResults.length;

      if (numberResultColumns !== this.meta.returns.length) {
        // Automatically number the columns in case the meta's returns field isn't applicable
        this.demoTableColumns = ['#', ...new Array(numberResultColumns).fill(0).map((_e, i) => (i + 1).toString())];
      } else {
        this.demoTableColumns = ['#', ...this.meta.returns];
      }

      // 2D provides multiple rows, whereas 1D data is a single row.
      if (is2D) {
        this.demoTableData = demoResults.map(rowToObject);
      } else {
        this.demoTableData = [rowToObject(demoResults)];
      }

      this.demoTableDS.data = this.demoTableData;
      if (!['open', 'high', 'low', 'close'].every((x) => this.meta?.returns?.includes(x))) {
        this.view = 'TABLE';
        this.csChartView = false;
      } else {
        const toPreso = (x) => x;
        this.xValues = this.demoTableData.map((item) => toPreso(item['#']));
        this.openValues = this.demoTableData.map((item) => toPreso(item['open']));
        this.highValues = this.demoTableData.map((item) => toPreso(item['high']));
        this.lowValues = this.demoTableData.map((item) => toPreso(item['low']));
        this.closeValues = this.demoTableData.map((item) => toPreso(item['close']));
        this.volumeValues = this.demoTableData.map((item) => toPreso(item['volume']));
        this.csChartView = true;
      }
    }
  }

  public async $clickTest(): Promise<void> {
    if (!this.meta || !this.hasMeta) {
      return;
    }

    const params = Object.fromEntries(this.meta.params.map(({ name }) => [name, this.feature[name]]));
    this._loading.demo = true;
    this.setTableData(undefined);

    const key = JSON.stringify([this.teamName, this.feature.path, this.identity.me.selectedProject?.name, this.index, params]);

    let instructions = [];

    const foundInCache = this._testCache.findIndex((x) => x.key === key);

    if (foundInCache != -1) {
      const data = this._testCache[foundInCache].data;
      instructions = this._testCache[foundInCache].hints;
      this.setTableData(data);
      const timestamp = this._testCache[foundInCache].timestamp;
      if (timestamp) this.sample_timestamp = new Date(timestamp / 1000000).toLocaleString();
    } else {
      try {
        const { timestamp, data, hints } = await this.projectsService.demo(
          this.teamName,
          this.feature.path,
          this.identity.me.selectedProject?.name,
          this.chart.schema,
          this.index,
          params,
        );

        if (hints && this.isLabel) instructions = hints;

        if (timestamp) this.sample_timestamp = new Date(timestamp / 1000000).toLocaleString();
        this.setTableData(data);
        this._testCache.push({ key: key, timestamp: timestamp, data: data, hints: instructions });
        if (this._testCache.length > 3) this._testCache.shift();
      } catch (error) {
        console.error(error);
        this.demoResults = 'error';
      }
    }

    if (instructions.length > 0) {
      this.chart.setFeatureAnnotations(instructions);
    }

    setTimeout(() => {
      this.optimizePageSize();
    }, 100);

    this._loading.demo = false;
  }

  public startEstimate(): void {
    if (this._isEstimating) {
      this._stopEstimating = true;
      return;
    }

    if (!this.isLabel) return;

    this._isEstimating = true;

    this._estimates = [];
    this.estimateAvg = null;
    const _this = this;
    async function getEstimate(countdown) {
      const params = Object.fromEntries(_this.meta.params.map(({ name }) => [name, _this.feature[name]]));
      const estimate = await _this.projectsService.estimate(
        _this.teamName,
        _this.feature.path,
        _this.identity.me.selectedProject.name,
        _this.chart.schema,
        10,
        params
      );
      _this._estimates.push(estimate[0]);
      _this.estimateAvg = (_this._estimates.reduce((partialSum, a) => partialSum + a, 0) / _this._estimates.length).toFixed(8);
      if (countdown && !_this._stopEstimating) getEstimate(countdown - 1);
      else _this._isEstimating = false;
      _this._stopEstimating = false;
    }
    getEstimate(99);
  }

  public changeView(newView: string): void {
    this.view = newView;
  }
}
