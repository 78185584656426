import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SubscriptionContainer } from 'src/app/models/subscription-container';

export namespace BaseWidgetComponent {
  export type Event<T = {}> = { id: string } & T;
}

@Component({
  selector: 'app-base-widget',
  templateUrl: './base-widget.component.html',
  styleUrls: ['./base-widget.component.scss']
})
export class BaseWidgetComponent implements OnDestroy {
  @Input() teamName: string;
  @Input() id: string = 'BaseWidget';
  @Input() parentId: string;

  @Output() onClose = new EventEmitter<BaseWidgetComponent.Event>();
  @Output() onRestore = new EventEmitter<BaseWidgetComponent.Event>();
  @Output() onMaximize = new EventEmitter<BaseWidgetComponent.Event>();
  @Output() onToggle = new EventEmitter<BaseWidgetComponent.Event>();
  public isMaximized: boolean = false;

  public widgetView: 'LIST' | 'DETAIL' | 'INSPECT' = 'LIST';
  protected _subs = new SubscriptionContainer();

  constructor() {}

  ngOnDestroy(): void {
    this._subs.dispose();
  }

  public close() {
    this.onClose.emit({ id: this.id });
  }

  public restore() {
    this.onRestore.emit({ id: this.id });
    this.isMaximized = false;
  }

  public maximize() {
    this.onMaximize.emit({ id: this.id });
    this.isMaximized = true;
  }

  public toggle() {
    this.onToggle.emit({ id: this.id });
    this.isMaximized = !this.isMaximized;
  }
}
