import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ChatService } from 'src/app/services/chat.service';
import { IdentityService } from 'src/app/services/identity.service';
import { ConfigService } from 'src/app/services/config.service';
import { isNil } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { debounce } from 'src/app/directives/debounce.decorator';
import { SocketService } from 'src/app/services/socket.service';

type MessageTurn = { isUser: boolean; content: string; isError?: boolean };

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './chat-widget.component.scss']
})
export class ChatWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) content: ElementRef<HTMLDivElement>;
  @ViewChild('chatInput') chatInput!: ElementRef<HTMLInputElement>;

  @Output() onViewExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onViewDefinition = new EventEmitter<{ projectName: string; definitionName: string }>();
  @Output() onViewBuild = new EventEmitter<{ projectName: string; runid: number }>();
  @Output() onViewAsset = new EventEmitter<{ projectName: string; assetName: string }>();
  @Output() onViewFeatureExplorer = new EventEmitter<{ projectName: string }>();
  @Output() onViewVault = new EventEmitter<{ projectName: string }>();
  @Output() onViewUpload = new EventEmitter();

  public turns: MessageTurn[] = [];
  public input: string = null;
  public loading = false;

  private _sessionId: string = null;
  private _chatIndex: number = null;

  public get userTurns() {
    return this.turns.filter(({ isUser }) => isUser);
  }

  constructor(
    private _chatService: ChatService,
    private _identity: IdentityService,
    private _socketService: SocketService
  ) {
    super();
  }

  public ngOnInit() {
    this._sessionId = this._identity.me.username;
    if ((<any>window).chat) (<any>window).chat.push(this);
    else (<any>window).chat = [this];

    this._socketService.joinRoom('chat');
    this._subs.add = this._socketService.subscribeToRoomEvents('chat', (data:any) => {
      if(data.origin === "experiment.change") {
       this.turns.push({ isUser: false, content: data.msg }) 
      }
    });

    this.getHistory();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  @debounce(100)
  private _scrollToBottom(): void {
    this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
  }

  @debounce(100)
  private _focusPromptBox(): void {
    setTimeout(this.chatInput.nativeElement.focus.bind(this.chatInput.nativeElement), 0);
  }

  public async getHistory() {
    if (!ConfigService.chatUrl) {
      console.error('ChatUrl not found.');
      return;
    }

    const sub = this._chatService.history(this._sessionId).subscribe((response: any) => {
      sub.unsubscribe();
      for (let item of response) this.turns.push(item);
      this._scrollToBottom();
    });
  }

  public async $submit(prompt?: string) {
    if (this.loading) return;

    this.loading = true;

    const content = prompt ?? this.input;
    this.turns.push({ isUser: true, content });
    this.input = '';
    this._chatIndex = null;
    this._scrollToBottom();

    this._chatService
      .chatAsync(this.teamName, this._identity.me.username, content, this._sessionId, this._identity.me.selectedProjectName)
      .then((response) => {
        this.loading = false;
        console.log({ response });

        this.turns.push({ isUser: false, content: response.error?.repr ?? response.output, isError: !isNil(response.error) });
      })
      .catch((err) => {
        this.loading = false;
        console.error(err);

        if (err instanceof HttpErrorResponse) {
          this.turns.push({ isUser: false, content: 'Internal Server Error', isError: true });
        }
      })
      .finally(() => {
        this._scrollToBottom();
        this._focusPromptBox();
      });
  }

  public $pressUp($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.userTurns.length === 0) return;
    if (this._chatIndex === 0) return;
    this._chatIndex = (this._chatIndex ?? this.userTurns.length) - 1;
    this.input = this.userTurns[this._chatIndex].content;
  }

  public $pressDown($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.userTurns.length === 0) return;
    if (this._chatIndex === this.userTurns.length - 1) return this.$pressEscape($event);
    if (this._chatIndex === null) return;
    this._chatIndex++;
    this.input = this.userTurns[this._chatIndex].content;
  }

  public $pressEscape($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this._chatIndex !== null) this._chatIndex = null;
    if (this.input) this.input = '';
  }
}
