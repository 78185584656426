import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { DataBuild } from 'src/app/models/data-build';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-builds-widget',
  templateUrl: './builds-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './builds-widget.component.scss']
})
export class BuildsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public runid: number;
  public rdsName: string;
  public user: string;
  public isArchived: boolean;

  @Input() build: DataBuild = null;
  @Output() onAsset = new EventEmitter<{ parentId: string; assetName: string }>();

  constructor(private _widgetService: WidgetService) {
    super();
    (<any>window).buildsWidget = this;
  }

  ngOnInit(): void {
    if (this.build) {
      this.rdsName = this.build.rdsName;
      this.runid = this.build.runid;
      this.user = this.build.user;
      this.isArchived = this.build.isArchived;
      this.widgetView = 'DETAIL';
    }

    this._subs.add = this._widgetService.resize$.subscribe(() => {
      if ((<any>window).builds) (<any>window).builds.onResize();
      if ((<any>window).build) (<any>window).build.onResize();
    });
  }

  onBuildClick($event) {
    this.rdsName = $event.rdsName;
    this.runid = $event.runid;
    this.user = $event.user;
    this.isArchived = $event.isArchived;

    this.widgetView = 'DETAIL';
  }

  onOpenAsset($event) {
    const input = { parentId: this.id, ...$event };
    this.onAsset.emit(input);
  }

  closeBuild() {
    this.rdsName = null;
    this.runid = null;
    this.user = null;
    this.isArchived = null;

    this.widgetView = 'LIST';
  }

  close() {
    if (this.widgetView === 'DETAIL') {
      this.closeBuild();
    } else {
      super.close();
    }
  }
}
