import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SerializedPoint } from 'src/app/models/point';
import { CanvasViewType } from 'src/app/models/workflow-canvas';
import SVGCanvasEventManager, { MoveEvent, PinchEvent } from 'src/app/models/svg-canvas-event-manager';
import { MatMenuTrigger } from '@angular/material/menu';

enum ResizeEdge {
  Horizontal = 1,
  Vertical = 2,
  Both = 3
}

@Component({
  selector: 'app-svg-canvas',
  templateUrl: './svg-canvas.component.html',
  styleUrls: ['./svg-canvas.component.scss']
})
export class SVGCanvasComponent implements OnInit, OnChanges {
  public readonly MIN_SCALE: number = 0.25;
  public readonly MAX_SCALE: number = 3.0;

  @Input() zoomie: boolean = true;
  @Input() movable: boolean = true;
  @Input() scalable: boolean = true;
  @Input() background: boolean = true;
  @Input() scale: number = undefined;
  @Input() eventHost: HTMLElement = undefined;
  @Input() sizeHost: HTMLElement = undefined;

  @Output() tap = new EventEmitter<void>();
  @Output() contextmenu = new EventEmitter<PointerEvent>();
  @Output() move = new EventEmitter<MoveEvent>();
  @Output() moveEnd = new EventEmitter<MoveEvent>();
  @Output() pinch = new EventEmitter<PinchEvent>();

  @ViewChild('svgCanvas', { static: true }) svgCanvas: ElementRef<SVGElement>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  private _animationFrame?: number = undefined;
  private _em!: SVGCanvasEventManager;
  private _resize$: ResizeObserver;

  public view: CanvasViewType = {
    width: 600,
    height: 400,
    x: 300,
    y: 100,
    scale: 1.25
  };
  public velocity: SerializedPoint = { x: 0, y: 0 };
  public friction: number = 1;

  public get transform() {
    return `matrix(${this.view.scale},0,0,${this.view.scale},${this.view.x},${this.view.y})`;
  }

  private get _eventHost(): HTMLElement {
    return this.eventHost ?? this._sizeHost.parentElement;
  }

  private get _sizeHost(): HTMLElement {
    return this.sizeHost ?? this._host.nativeElement;
  }

  /* #region Lifecycle */
  constructor(private _host: ElementRef) {}

  public ngOnInit(): void {
    this.setView();
    this.setCanvasSize();

    this._resize$ = new ResizeObserver(this.$resize.bind(this));
    this._resize$.observe(this._eventHost);
    this._em = new SVGCanvasEventManager(this._host.nativeElement);
    this._em.$tap(this.$tap);
    if (this.movable) {
      this._em.$move(this.$move);
      this._em.$moveEnd(this.$moveEnd);
    }
    if (this.scalable) this._em.$pinch(this.$pinch);

    if (this.zoomie) setTimeout(this._zoomie.bind(this), 100);
    else this.view.scale = this.scale ?? 1.0;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('scale' in changes) this.view.scale = this.scale ?? 1.0;
  }

  public ngOnDestroy() {
    this._resize$.unobserve(this._eventHost);
    this._em.setdown();
  }
  /* #endregion */

  /* #region Events */
  public $resize([$ev]: [ResizeObserverEntry]) {
    const { width: oldWidth, height: oldHeight } = this.view;
    const { width: newWidth, height: newHeight } = $ev.contentRect;
    const changedEdge = (oldWidth !== newWidth ? ResizeEdge.Horizontal : 0) | (oldHeight !== newHeight ? ResizeEdge.Vertical : 0);
    if (changedEdge) this.setCanvasSize(changedEdge);
  }

  @HostListener('window:orientationchange')
  public $orientationChange() {
    this.setCanvasSize();
  }

  @HostListener('mousewheel', ['$event'])
  public $mouseWheel($ev: WheelEvent): void {
    if (!this.scalable) return;
    const { deltaY, clientX, clientY } = $ev;
    if (isNaN(deltaY) || !deltaY) return;
    const scale = this.view.scale + (-1 * deltaY) / 1000;
    this.setScale(scale, { x: clientX, y: clientY });
  }

  public $contextmenu = ($ev: PointerEvent) => {
    $ev.preventDefault();
    $ev.stopPropagation();
    this.contextmenu.emit($ev);
  };

  public $tap = (): void => {
    this.tap.emit();
  };

  public $move = ($ev: MoveEvent): void => {
    const { delta } = $ev.detail;
    this.view.x += delta.x;
    this.view.y += delta.y;
    this.move.emit($ev);
  };

  public $moveEnd = ($ev: MoveEvent): void => {
    if (this._animationFrame) cancelAnimationFrame(this._animationFrame);
    this.velocity = $ev.detail.delta;
    this.friction = 0.85;
    this._animationFrame = requestAnimationFrame(this.glideCanvas.bind(this));
    this.moveEnd.emit($ev);
  };

  public $pinch = ($ev: PinchEvent): void => {
    const { x, y } = $ev.detail;
    this.$move($ev);
    this.setScale($ev.detail.scale, { x, y });
    this.pinch.emit($ev);
  };
  /* #endregion */

  public setView(): void {
    const { innerWidth, innerHeight } = window;
    Object.assign(this.view, {
      width: innerWidth,
      height: innerHeight,
      x: innerWidth / 2,
      y: (innerHeight - 56) / 2,
      scale: this.scale ?? 1.25
    });
  }

  public setCanvasSize(resizeEdge: ResizeEdge | undefined = undefined): void {
    const { top: offsetTop, left: offsetLeft } = this.svgCanvas.nativeElement.getBoundingClientRect();
    const { offsetWidth: width, offsetHeight: height } = this._sizeHost;
    const updateBoth = resizeEdge === undefined || resizeEdge === ResizeEdge.Both;

    Object.assign(this.view, {
      ...(updateBoth || resizeEdge & ResizeEdge.Horizontal ? { width, x: width / 2, offsetLeft } : {}),
      ...(updateBoth || resizeEdge & ResizeEdge.Vertical ? { height, y: height / 2, offsetTop } : {})
    });
  }

  public setScale(scale: number, location: SerializedPoint): void {
    // NOTE: This could be improved to zoom in on the cursor instead of near it.
    // If I recall correctly, I did this for Atlas(?) in rtc.
    scale = Math.min(Math.max(scale, this.MIN_SCALE), this.MAX_SCALE); // clamp
    const xFactor = scale / this.view.scale - 1; // trial & error
    Object.assign(this.view, {
      scale,
      x: this.view.x - (location.x - this.view.x) * xFactor,
      y: this.view.y - (location.y - this.view.y) * xFactor
    });
  }

  private _zoomie(): void {
    const startScale = 1.25;
    const endScale = this.scale ?? 1.0;
    const totalTime = (1000 / 60) * 15;
    let startTime: number = undefined;
    let done = false;
    let previousTimeStamp: number = undefined;

    const ease = (p: number) => startScale - Math.sqrt(p / Math.pow(startScale - endScale, -2));

    const step = (timeStamp: number) => {
      startTime = startTime ?? timeStamp;
      if (previousTimeStamp !== timeStamp) {
        this.view.scale = Math.max(ease((timeStamp - startTime) / totalTime), endScale);
        done = this.view.scale === 1;
      }

      if (timeStamp < startTime + totalTime) {
        previousTimeStamp = timeStamp;
        this._animationFrame = !done ? requestAnimationFrame(step.bind(this)) : undefined;
      }
    };
    this._animationFrame = requestAnimationFrame(step.bind(this));
  }

  public glideCanvas(): void {
    this.friction -= 0.01;
    if (this.friction < 0.01) this.friction = 0.01;
    this.velocity = {
      x: this.velocity.x * this.friction,
      y: this.velocity.y * this.friction
    };
    if (Math.abs(this.velocity.x) < 0.02 && Math.abs(this.velocity.y) < 0.02) {
      this.friction = 1.0;
      return;
    }

    this.view.x += this.velocity.x;
    this.view.y += this.velocity.y;
    this._animationFrame = requestAnimationFrame(this.glideCanvas.bind(this));
  }
}
