import { SciChartSurface } from 'scichart';
import { environment } from '../../environments/environment';
import { ConfigService } from '../services/config.service';
import { query } from '@angular/animations';

export function initializeSciChart() {
  console.log('init scichart');
  SciChartSurface.setServerLicenseEndpoint('api/scichart_license');

  SciChartSurface.setLicenseCallback((queryString) => fetch(`${ConfigService.apiUrl}/api/scichart_license?` + queryString));

  if (!ConfigService.scichartLicenseKey) return () => SciChartSurface.UseCommunityLicense();
  return () => SciChartSurface.setRuntimeLicenseKey(ConfigService.scichartLicenseKey);
}
