// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-row {
  font-style: bold;
}

td {
  overflow: hidden;
  width: 100px;
  font-size: 10pt;
  white-space: nowrap;
}

th {
  font-size: 10pt;
  width: 100px;
}

.table-container {
  overflow: auto;
  height: 400px;
  max-height: 400px;
}

table {
  width: 100%;
}

.run-info {
  padding: 10px;
  font-size: 13px;
}

textarea {
  display: block;
  width: 95%;
  height: 300px;
}

.rank-0 {
  background-color: rgba(255, 255, 0, 0.1);
}

.rank-1 {
  background-color: rgba(255, 255, 0, 0.06);
}

.compact {
  height: 38px !important;
}

.header {
  cursor: move;
}

.selected {
  background-color: #f2f2f2;
}

.mat-mdc-row .mat-mdc-cell {
  cursor: pointer;
}

.tags-list {
  width: 100%;
}

.vault-link {
  color: blue;
  text-decoration: none;
}

.vault-link:hover {
  text-decoration: underline;
}

.hidden-paginator {
  visibility: hidden;
}

mat-chip-grid {
  margin-top: -10px;
}

mat-progress-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header mat-icon {
  margin-top: -5px;
}

.status-icon {
  min-width: 23px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/perf-table/perf-table.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;AACF;;AAGA;EACE,cAAA;EACA,aAAA;EACA,iBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EAEE,aAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,UAAA;EACA,aAAA;AADF;;AAIA;EACE,wCAAA;AADF;;AAIA;EACE,yCAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,WAAA;EACA,qBAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,mBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,eAAA;AADF","sourcesContent":[".header-row {\n  font-style: bold;\n}\n\ntd {\n  overflow: hidden;\n  width: 100px;\n  font-size: 10pt;\n  white-space: nowrap;\n}\n\nth {\n  font-size: 10pt;\n  width: 100px;\n}\n\n\n.table-container {\n  overflow: auto;\n  height: 400px;\n  max-height: 400px;\n}\n\ntable {\n  width: 100%;\n}\n\n.run-info {\n  //padding: var(--content-margin) var(--content-margin) 0;\n  padding: 10px;\n  font-size: 13px;\n}\n\ntextarea {\n  display: block;\n  width: 95%;\n  height: 300px;\n}\n\n.rank-0 {\n  background-color: rgba(255, 255, 0, 0.1);\n}\n\n.rank-1 {\n  background-color: rgba(255, 255, 0, 0.06);\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.header {\n  cursor: move;\n}\n\n.selected {\n  background-color: #f2f2f2;\n}\n\n.mat-mdc-row .mat-mdc-cell {\n  cursor: pointer;\n}\n\n.tags-list {\n  width: 100%;\n}\n\n.vault-link {\n  color: blue;\n  text-decoration: none;\n}\n\n.vault-link:hover {\n  text-decoration: underline;\n}\n\n.hidden-paginator {\n  visibility: hidden;\n}\n\nmat-chip-grid {\n  margin-top: -10px;\n}\n\nmat-progress-bar {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.header mat-icon {\n  margin-top: -5px;\n}\n\n.status-icon {\n  min-width: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
