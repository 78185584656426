import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { FileUploadDialogComponent } from '../components/file-upload-dialog/file-upload-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(public dialog: MatDialog) {}

  async promptForUpload(teamName: string) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '600px',
      position: { bottom: '0px', right: '0px' },
      disableClose: true, //disable closing dialog via esc btn
      hasBackdrop: false, //modeless
      panelClass: 'no-overflow-dialog-container',
      data: { teamName: teamName }
    });

    const results = await lastValueFrom(dialogRef.afterClosed());
    return results;
  }
}
