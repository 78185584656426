import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-raw-data-widget',
  templateUrl: './raw-data-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './raw-data-widget.component.scss']
})
export class RawDataWidgetComponent extends BaseWidgetComponent implements OnInit {
  public path: string;
  public fileType: string;

  constructor(private _widgetService: WidgetService) {
    super();
  }

  ngOnInit(): void {
    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      setTimeout(() => {
        (<any>window).raw.optimizePageSize();
      }, 100);
    });
  }

  onViewData(input: any) {
    this.path = input.path;
    this.fileType = input.fileType;

    this.widgetView = 'DETAIL';
  }

  close() {
    if (this.widgetView === 'DETAIL') {
      this.path = null;
      this.fileType = null;

      this.widgetView = 'LIST';
    } else {
      super.close();
    }
  }
}
