import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { Pid } from 'src/app/models/pid';
import { MatTableDataSource } from '@angular/material/table';
import { IdentityService } from 'src/app/services/identity.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatStepper } from '@angular/material/stepper';
import { DataBuild } from 'src/app/models/data-build';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class BuildComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('stepper') private stepper: MatStepper;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() rdsName: string;
  @Input() runId: number;
  @Input() username: string;
  @Input() isArchived: boolean;

  @Output() onOpenAsset = new EventEmitter<{ assetName: string }>();
  @Output() closeBuild = new EventEmitter();

  private _buildStatusMap = {
    'building data': 0,
    'combining data': 1,
    'indexing test/train': 2,
    'splitting test/train': 2,
    'indexing precache': 3,
    'building precache': 3,
    'combining precache': 4,
    complete: 5
  };
  private _buildStatus = ['building data', 'combining data', 'indexing test/train', 'indexing precache', 'combining precache', 'complete'];
  private _reloadInterval: any;
  private _previousIndex = 0;

  public log: string = 'Loading...';
  public config: string = 'Loading...';
  public isLoading: boolean = true;
  public pageSize = 10;
  public pageSizes = [5, 10, 15, 20];
  public processView = 'ALL';
  public isUpdating: boolean = false;
  public displayedColumns: string[] = ['scriptName', 'pid', 'username', 'startTime', 'endTime'];
  public dataSource = new MatTableDataSource<Pid>([]);
  public all = [];
  public noEndTime = [];
  public showAll: boolean = false;
  public build: DataBuild = null;
  public tags: Set<string>;
  public cancelledStatus: string = undefined;
  public errorStatus: string = undefined;
  public inspector: { type: 'audit'; logFile: string } | { type: 'screen'; command: string } | undefined = undefined;
  public terminalId = new Date().getTime().toString();

  public get processLabel() {
    return `Processes (${this.all.filter(({ isRunning }) => isRunning).length})`;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _http: HttpClient,
    private _dataBuildsService: DataBuildsService,
    private _identity: IdentityService,
    private _buildsService: DataBuildsService,
    private _snackbar: MatSnackBar,
    private _menuService: MenuService
  ) {}

  public ngOnInit(): void {
    (<any>window).build = this;

    if (this.isWidget) {
      this.load();
    } else {
      this._activatedRoute.params.subscribe((params) => {
        Object.assign(this, {
          teamName: params.team,
          rdsName: params.rds,
          runId: params.runid,
          username: params.username,
          isArchived: params.isArchived == 'true' ? true : false
        });

        this.load();
        this._menuService.delayedSetActive('builds');
      });
    }
  }

  public ngOnDestroy(): void {
    if (this._reloadInterval) clearInterval(this._reloadInterval);
  }

  public async load(): Promise<void> {
    this.build = await this._dataBuildsService.loadOneAsync(this.teamName, this.runId);
    this.tags = this.build.tags ? new Set(this.build.tags.split(',')) : new Set();

    const sub = this._dataBuildsService.loadPids(this.teamName, this.rdsName, this.runId, this.isArchived).subscribe({
      error: (error) => console.error(error),
      next: (response: Pid[]) => {
        sub.unsubscribe();
        this.isLoading = false;
        this.all = response;
        this.noEndTime = response.filter((x) => x.endTime == null);

        if (this.noEndTime.length > 0) {
          if (!this._reloadInterval) {
            this._reloadInterval = setInterval(() => {
              this.load();
            }, 2000);
          }
        } else {
          if (this._reloadInterval) {
            clearInterval(this._reloadInterval);
            this._reloadInterval = null;
          }
        }
        this.changeView(this.processView);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.optimizePageSize();

          const index = Object.keys(this._buildStatusMap).includes(this.build.status) ? this._buildStatusMap[this.build.status] : -1;

          if (this.build.errors) this.errorStatus = this._buildStatus[index < 0 ? 0 : index];
          if (this.build.cancelled) this.cancelledStatus = this._buildStatus[index < 0 ? 0 : index];

          // this.nextStep(index);
        }, 0);
      }
    });
  }

  public nextStep(index: number): void {
    if (this._previousIndex === 0 && index > 0) {
      this._previousIndex = index;
      for (let i = 0; i < index; i++) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }

      return;
    }

    if (this._previousIndex === index) return;

    for (let i = 0; i < index - this._previousIndex; i++) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }

    this._previousIndex = index;
  }

  public changeView(view: string): void {
    this.processView = view;
    switch (view) {
      case 'ALL':
        this.dataSource.data = this.all;
        break;
      case 'ACTIVE':
        this.dataSource.data = this.all.filter((x) => x.endTime == null);
        break;
      case 'INACTIVE':
        this.dataSource.data = this.all.filter((x) => x.endTime != null);
        break;
    }
  }

  public openFile(path: string, callback: any): void {
    const sub = this._http
      .get(`${ConfigService.apiUrl}/file/${this.teamName}/${encodeURI(path.replace(/\//g, '\\'))}`, { responseType: 'text' })
      .subscribe((data: any) => {
        callback(data.toString().trim());
        sub.unsubscribe();
      });
  }

  public onResize(): void {
    this.optimizePageSize();
  }

  public optimizePageSize(): void {
    const headerHeight = 48;
    const tabHeight = 30;
    const rowHeight = 40;
    const paginatorHeight = 56;
    const footerHeight = 48;
    let tableHeight = window.innerHeight - headerHeight - tabHeight - paginatorHeight - footerHeight;
    if (this.isWidget) {
      const parent: any = <any>document.querySelector('app-build');
      if (parent) {
        const parentHeight: number = parent.offsetHeight;
        tableHeight = parentHeight - headerHeight - tabHeight - paginatorHeight - footerHeight;
        // const logHeight: any = <any>document.querySelector('app-log');
        // console.log('log', this.applog.nativeElement.style.height);
      }
    }

    this.pageSize = Math.floor(tableHeight / rowHeight);
    this.paginator._changePageSize(this.pageSize);
    this.pageSizes = [
      this.pageSize,
      ...Array(20)
        .fill(0)
        .map((v, i) => (i + 1) * 5)
        .filter((v) => v <= this.pageSize)
    ];
  }

  public toggleArchived(): void {
    this.isUpdating = true;
    this.isArchived = !this.isArchived;

    if (this.isArchived) this.archive();
    else this.restore();
  }

  public archive(): void {
    const sub = this._dataBuildsService.archive(this.teamName, this.runId).subscribe({
      error: (error) => {
        sub.unsubscribe();
        this.isArchived = !this.isArchived;
        this.isUpdating = false;
        console.log(error);
        this.openSnackBar('An error occured. See logs for details.', 'OK');
      },
      next: (results) => {
        sub.unsubscribe();
        this.isUpdating = false;
        this._router.navigate([this.teamName, 'data', 'build', this.rdsName, this.runId, this.username, true]);
      }
    });
  }

  public restore(): void {
    const sub = this._dataBuildsService.restore(this.teamName, this.runId).subscribe({
      error: (error) => {
        sub.unsubscribe();
        this.isArchived = !this.isArchived;
        this.isUpdating = false;
        console.log(error);
        this.openSnackBar('An error occured. See logs for details.', 'OK');
      },
      next: (results) => {
        sub.unsubscribe();
        this.isUpdating = false;
        this._router.navigate([this.teamName, 'data', 'build', this.rdsName, this.runId, this.username, false]);
      }
    });
  }

  public openSchema(): void {
    this._router.navigate([this.teamName, 'data', 'schema', this.rdsName]);
  }

  public openAsset(): void {
    if (this.isWidget) {
      this.onOpenAsset.emit({ assetName: this.rdsName });
    } else {
      this._router.navigate([this.teamName, 'data', 'asset', this.rdsName]);
    }
  }

  close() {
    this.closeBuild.emit();
  }

  public openSnackBar(message: string, action: string): void {
    const durationInSeconds = 10;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  public addTag(event: MatChipInputEvent): void {
    if (event.value) {
      this.tags.add(event.value);
      event.chipInput!.clear();
      this.updateTags();
    }
  }

  public removeTag(keyword: string): void {
    this.tags.delete(keyword);
    this.updateTags();
  }

  public async updateTags(): Promise<void> {
    const tags = [...this.tags].join(',');
    await this._dataBuildsService.updateTagsAsync(this.teamName, this.runId, this.isArchived, tags);
  }

  public onShowAll(mcb: MatCheckboxChange): void {
    this.dataSource.data = mcb.checked ? this.all : this.noEndTime;
  }

  public async stop(): Promise<void> {
    if (!confirm('Are you sure you want to canel this build?')) return;

    const sub = this._buildsService.stop(this.teamName, this.rdsName, this.runId.toString()).subscribe((results: any) => {
      sub.unsubscribe();
      this.build.isRunning = false;
      this.build.cancelled = true;
    });
  }

  public getProcessLogFile(proc: Pid): string | undefined {
    if (proc.endTime && proc.endTime.length > 0) {
      const scriptParts = proc.scriptName.split(' ');
      if (scriptParts[0] === 'data_worker.py') {
        const chunk_start = +scriptParts[2];
        const chunk_end = +scriptParts[4];

        return `tmp%2Fdata%2F${this.rdsName}_${chunk_start}_${chunk_end}.h5`;
      } else if (scriptParts[0] === 'precache_worker.py') {
        const chunk_start = +scriptParts[2];
        const chunk_end = +scriptParts[4];

        return `tmp%2Fprecache%2Fprecache_${chunk_start}_${chunk_end}.h5`;
      }
    }

    return undefined;
  }

  public doesUsernameMatch(proc: Pid): boolean {
    const { username: processUsername, host: processHost } = proc;
    const { username: identityUsername, hosts: identityHosts } = this._identity.me;

    if (processUsername === identityUsername) return true;

    const hostUsernameMapping = identityHosts.find(({ hostname }) => hostname === processHost);
    if (hostUsernameMapping) return hostUsernameMapping.username === processUsername;

    return false;
  }

  public $clickProcess(proc: Pid): void {
    const logFile = this.getProcessLogFile(proc);
    this.inspector = logFile
      ? { type: 'audit', logFile }
      : proc.sessionName && this.doesUsernameMatch(proc)
        ? { type: 'screen', command: `screen -T xterm-256color -d -r ${proc.sessionName}; exit;` }
        : undefined;
  }
}
