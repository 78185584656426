import { Component, ViewEncapsulation, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { IdentityService } from './services/identity.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Team } from './models/team';
import { Project } from './models/project';
import { TeamsService } from './services/teams.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { ConfigService } from './services/config.service';
import { MenuService } from './services/menu.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsService } from './services/projects.service';
import { SettingsService } from './services/settings.service';
import { WidgetService } from './services/widget.service';
import { BaseWidgetComponent } from './components/strategy-grid/base-widget/base-widget.component';
import { HeartbeatService } from './services/heartbeat.service';
import { VersionService } from './services/version.service';

const CHANGELOG_URL = 'https://docs.lit.ai/reference/changelog/';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'lit';
  team: Team | undefined = undefined;
  menuItems = [];
  projects: Project[] = [];
  allProjects: Project[] = [];
  subs: any[] = [];
  color: string;
  build: string;
  version: string | undefined;

  public get features(): any {
    return ConfigService.features || {};
  }

  public get hasClipboard(): boolean {
    return navigator.clipboard !== undefined;
  }

  public get initialized(): boolean {
    return ConfigService.initialized;
  }

  public get changelogURL(): string {
    if (this.version !== undefined) {
      return `${CHANGELOG_URL}#${this.version}`;
    }
    return CHANGELOG_URL;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public identity: IdentityService,
    public menuService: MenuService,
    public dialog: MatDialog,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _teams: TeamsService,
    private _keycloak: KeycloakService,
    private _projectsService: ProjectsService,
    private _settings: SettingsService,
    private _widgets: WidgetService,
    private _heartbeatService: HeartbeatService,
    private _version: VersionService
  ) {
    (<any>window).app = this;

    this._matIconRegistry.addSvgIcon('flame', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/flame.svg'));
    this._matIconRegistry.addSvgIcon('data', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/data.svg'));
    this._matIconRegistry.addSvgIcon('beaker', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/beaker.svg'));
    this._matIconRegistry.addSvgIcon('audit', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/audit.svg'));
    this._matIconRegistry.addSvgIcon('folder', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/folder.svg'));
    this._matIconRegistry.addSvgIcon('folder_open', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/folder_open.svg'));

    document.body.parentElement.classList.add(this._settings.theme);
    document.body.classList.add(`bg-${this._settings.backgroundFit}`);
    document.body.style.cssText = `--bg-${this._settings.backgroundType}: ${this._settings.appBackground}`;
  }

  ngOnInit(): void {
    if (!this.initialized) return;

    this._keycloak.keycloakEvents$.subscribe((e) => {
      switch (e.type) {
        case KeycloakEventType.OnAuthError:
          console.debug('OnAuthError');
          break;
        case KeycloakEventType.OnAuthLogout:
          console.debug('OnAuthLogout');
          break;
        case KeycloakEventType.OnAuthRefreshError:
          console.debug('OnAuthRefreshError');
          this.logout();
          break;
        case KeycloakEventType.OnAuthRefreshSuccess:
          this._keycloak.getToken().then((token) => {
            localStorage.setItem('id_token', token);
          });
          break;
        case KeycloakEventType.OnAuthSuccess:
          console.debug('OnAuthSuccess');
          break;
        case KeycloakEventType.OnReady:
          console.debug('OnReady');
          break;
        case KeycloakEventType.OnTokenExpired:
          this._keycloak.updateToken(120).then((response) => {
            this._keycloak.getToken().then((token) => {
              console.log('token refreshed');
              localStorage.setItem('id_token', token);
            });
          });
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.initialized) return;

    this._heartbeatService.startHeartbeat();

    if (this.identity && this.identity.me) {
      this.subs.push(
        this.identity.me$.subscribe((me) => {
          if (!me.selectedTeamName) {
            const lastTeamSelected = localStorage.getItem('lastTeamSelected');
            if (!lastTeamSelected) {
              this.router.navigate(['teams']);
            } else {
              this.identity.setTeam(lastTeamSelected);
            }
          }

          if (me.selectedTeamName) {
            const psub = this._projectsService.load(me.selectedTeamName).subscribe((response: Project[]) => {
              psub.unsubscribe();
              this.projects = response;
              if (this.projects) {
                this.projects = this.projects.sort((a, b) => a.name.localeCompare(b.name));
                this.allProjects = this.projects;
                this.paginateProjects(1);
              }

              if (this.identity.me.selectedProjectName)
                this.identity.me.selectedProject = this.projects.find((x) => x.name === this.identity.me.selectedProjectName);
            });

            this._version.get().then(({ version }) => {
              this.version = version;
            });
          }
        })
      );

      this.subs.push(
        this._teams.load().subscribe((teams) => {
          this.menuItems = teams;
        })
      );

      this.subs.push(
        this.menuService.activeMenuItem$.subscribe((activeMenuItem: string) => {
          this.menuService.activeMenuItem = activeMenuItem;
        })
      );
    }

    this.identity.reload();
  }

  selectTeam(element) {
    if (element.name === '[New Team]') {
      this.router.navigate(['/']);
      return;
    }

    if (this.identity.me.selectedTeamName === element.name) return;

    localStorage.setItem('lastTeamSelected', element.name);
    this.identity.setTeam(element.name);
    this.identity.setProject(null);
    window.location.href = `${ConfigService.hostUrl}/${element.name}/grid`;
  }

  selectProject(project) {
    this.identity.setProject(project);
    localStorage.setItem('lastProjectSelected', project?.name);
  }

  async addProject() {
    const projectName = prompt('Please enter a project name');
    if (!projectName || projectName.trim().length === 0) {
      return;
    }

    const exists = this.projects.find((x) => x.name == projectName);
    if (exists) {
      alert(`Project with name ${projectName} already exists`);
      return;
    }

    const project: any = await this._projectsService.add(this.identity.me.selectedTeamName, projectName);
    this.projects.push(project);
    this.selectProject(project);
  }

  ngOnDestroy(): void {
    this.subs.forEach((x) => x.unsubscribe());
    this._heartbeatService.stopHeartbeat();
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('id_token');
    localStorage.removeItem('me');
    this._keycloak.logout(window.location.href);
  }

  navigate(route: string[]) {
    this.menuService.setActive(route[route.length - 1]);
    this.router.navigate(route);
  }

  openSettings() {
    const widget = this._widgets.createWidget('SettingsWidget', this.team?.name ?? '', undefined, {
      defaultSpace: { minItemRows: 16, minItemCols: 27 },
      outputs: {
        onClose: ($event: BaseWidgetComponent.Event) => {
          // use setTimeout, otherwise gridster leaves gridster-preview behind
          setTimeout(() => this._widgets.removeWidget(this.identity.me.selectedTeamName, $event.id));
        },
        onRestore: ($event: BaseWidgetComponent.Event) => {
          this._widgets.restoreWidget(this.identity.me.selectedTeamName, $event.id);
        },
        onMaximize: ($event: BaseWidgetComponent.Event) => {
          this._widgets.maximizeWidget(this.identity.me.selectedTeamName, $event.id);
        },
        onToggle: ($event: BaseWidgetComponent.Event) => {
          this._widgets.toggleWidget(this.identity.me.selectedTeamName, $event.id);
        }

      }
    });
    this._widgets.addWidget(this.identity.me.selectedTeamName, widget);
  }

  openInit() {
    this.router.navigate(['/init']);
  }

  openUserManager() {
    window.location.href = `${ConfigService.keycloakUrl}/admin/${ConfigService.keycloakRealm}/console/#/${ConfigService.keycloakRealm}/users`;
  }

  public currentPage = 1;
  public pageSize = 10;
  public hasPrevPage = false;
  public hasNextPage = false;

  paginateProjects(page: number) {
    const start = (page - 1) * this.pageSize;
    const end = page * this.pageSize;

    this.hasNextPage = false;
    if (end < this.allProjects.length) this.hasNextPage = true;

    this.hasPrevPage = false;
    if (start > 0) this.hasPrevPage = true;

    this.projects = this.allProjects.slice(start, end);
  }

  next() {
    if (!this.hasNextPage) return;
    this.currentPage++;
    this.paginateProjects(this.currentPage);
  }

  prev() {
    if (!this.hasPrevPage) return;
    this.currentPage--;
    this.paginateProjects(this.currentPage);
  }
}
