import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, ElementRef } from '@angular/core';
import { PerfDataEntry, PerfService } from 'src/app/services/perf.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { VaultService } from 'src/app/services/vault.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { Run } from 'src/app/models/run';
import { GpusService } from 'src/app/services/gpus.service';
import { debounce } from 'src/app/directives/debounce.decorator';

@Component({
  selector: 'app-perf-table',
  templateUrl: './perf-table.component.html',
  styleUrls: ['./perf-table.component.scss'],
  providers: [PerfService]
})
export class PerfTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.inEditMode) this.inEditMode = false;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger, static: false }) contextMenuTrigger: MatMenuTrigger;

  @Input() teamName: string;
  @Input() run: any;
  @Input() displayedColumns: string[] = [];
  @Input() dynamicColumns: any[] = [];
  @Input() live: boolean = false;

  @Output() onTagsUpdated: EventEmitter<any> = new EventEmitter();
  @Output() onVaultItemClick: EventEmitter<{vaultName: string}> = new EventEmitter();
  @Output() onProcessView = new EventEmitter<{command: string, title: string}>();

  public devices: string[] = ['cpu'];
  public allGPUS = [];

  private _subs = new SubscriptionContainer();
  private _pollingTimer?: number;

  public inEditMode = false;
  public epochTime: number = null;
  public textData: string = '';
  public dataSource = new MatTableDataSource<PerfDataEntry>([]);
  public gpuEnabled: boolean = false;
  public selectedRow: any;
  public vaults: any[] = [];
  public all: PerfDataEntry[] = [];

  public contextMenuPosition = { x: '0px', y: '0px' };
  public tags: Set<string>;

  public isPolling: boolean = false;

  public pageInfo = {
    totalPages: 0,
    currentPage: 0,
    hasNextPage: false,
    hasPrevPage: false,
    total: 0,
    sortColumn: 'epoch',
    sortOrder: 'desc',
    pageSize: 10
  };

  constructor(
    private _perf: PerfService,
    private _socket: SocketService,
    private _vaultService: VaultService,
    private _router: Router,
    private _snackbar: MatSnackBar,
    private _settings: SettingsService,
    private _changeDetectorRefs: ChangeDetectorRef,
    private _elRef: ElementRef,
    private _experimentsService: ExperimentsService,
    private gpuService: GpusService
  ) {}

  public ngOnInit(): void {
    (<any>window).grid = this;

    this.subscribeToSocketEvents();

    if (this.live) {
      this._subs.add = this._settings.pollingRate$.subscribe(this.$changePollingRate.bind(this));
      this.$changePollingRate(this._settings.pollingRate);
    }
  }

  isSorting = false;
  public ngAfterViewInit(): void {

    window.addEventListener(
      "resize-experiments",
      (e) => {
        setTimeout(() => {
          this.optimizePageSize();
        }, 500);

      },
      false,
    );

    this.sort.sortChange.subscribe(async (value: Sort) => {
      this.isSorting = true;
      this.dataSource.data = [];
      this.pageInfo.sortColumn = value.active;
      if(value.direction && value.direction.length > 0) {
        this.pageInfo.sortOrder = value.direction;
      } else {
        this.pageInfo.sortOrder = this.pageInfo.sortOrder === 'asc' ? 'desc' : 'asc';
      }

      this.paginator.pageIndex = 0;
      this.pageInfo.currentPage = 0;

      await this.fetchRuns();
      this.isSorting = false;
    });


    this.fetchAll();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const gpusub = this.gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.resetDevices();
    });
  }

  public ngOnDestroy(): void {
    this._subs.dispose();
    this._socket.leaveRoom();
    if (this._pollingTimer) clearTimeout(this._pollingTimer);
  }

  public onTableResize(): void {
    this.optimizePageSize();
  }

  public optimizePageSize(): void {
    const oldPageSize = this.pageInfo.pageSize;
    const x = this._elRef.nativeElement.offsetHeight;
    this.pageInfo.pageSize = Math.round(0.025 * x - 4);
    this.paginator._changePageSize(this.pageInfo.pageSize);
    if(oldPageSize < this.pageInfo.pageSize && this.pageInfo.pageSize > this.dataSource.data.length) {
      this.fetchRuns();
    }
  }

  @debounce(500)
  public async fetchAll(): Promise<void> {
    await Promise.all([this.fetchVault(), this.fetchRuns()]);
  }

  @debounce(500)
  public async fetchVault(): Promise<void> {
    let vaults = await this._vaultService.loadAsync(this.teamName);
    this.vaults = vaults;
  }

  intialized = false;
  @debounce(500)
  public async fetchRuns(): Promise<void> {
    if(this.isPolling) {
      return;
    }
    this.isPolling = true;
    this._changeDetectorRefs.detectChanges();

    const { run, project, data, tags, total, totalPages, currentPage, hasNextPage, hasPrevPage } = await this._perf.pageAsync(
      this.teamName,
      this.run.project,
      this.run.runid,
      this.pageInfo.pageSize,
      this.pageInfo.currentPage + 1,
      this.pageInfo.sortColumn,
      this.pageInfo.sortOrder
    );

    this.pageInfo.total = total;
    this.pageInfo.totalPages = totalPages;
    this.pageInfo.currentPage = this.paginator.pageIndex;
    this.pageInfo.hasNextPage = hasNextPage;
    this.pageInfo.hasPrevPage = hasPrevPage;
    if(!this.intialized) {
      this.optimizePageSize();
      this.intialized = true;
    }

    if (run !== this.run.runid && project !== this.run.project) return;

    if(!data) {
      this.isPolling = false;
      return;
    }
    data.forEach(x => {
      var found = this.dataSource.data.find(y => y.epoch == x.epoch);
      if(found) {
        found = {...x}; // update row
      }
      else {
        // figure out the correct place to insert
        var insertHere = 0;
        for(let i=0;i<this.dataSource.data.length;i++) {
          var left = this.dataSource.data[i][this.pageInfo.sortColumn];
          var right = x[this.pageInfo.sortColumn];
          if(this.pageInfo.sortColumn == "desc") {
            if(left < right) {
              insertHere = i;
              break;
            }
          }
          else {
            if(right < left) {
              insertHere = i;
              break;
            }
          }
        }

        // console.log(`inserting ${x.epoch} at ${insertHere}`)
        this.dataSource.data.splice(insertHere, 0, x); // insert it
      }
    })
    this.dataSource.paginator = this.paginator;
    this._changeDetectorRefs.detectChanges();

    setTimeout(() => this.updatePaginator(), 10);
    this.gpuEnabled = typeof this.run.gpu === 'number';
    this.tags = tags ? new Set(tags.split(',')) : new Set();
    this.isPolling = false;
  }

  public subscribeToSocketEvents(): void {
    this._socket.joinRoom();
    this._subs.add = this._socket.events.subscribe((msg: any) => {});

    this._subs.add = this._socket.subscribeToRoomEvents('experiments', (packet:any) => {
      var msg:string = packet.msg;
      const prefix = "update experiment";
      if(msg.startsWith(prefix)) {
        var runid = +msg.substring(prefix.length);
        if(runid == this.run.runid) {
          this.fetchRuns();
        }
      }
    });
  }

  public formatElapsedTime(secNum: number): string {
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = Math.floor(secNum - hours * 3600 - minutes * 60);

    return [hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(':');
  }

  public getDynamicColumns(type: string): any[] {
    return this.dynamicColumns.filter((x) => x.type == type);
  }

  onProcessViewClick(item) {
    this.onProcessView.emit({command: `screen -d -r ${item.session_name}`, title: `run id: ${item.runid}`});
  }


  public onStateMenu(event: MouseEvent): boolean {
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    return false;
  }

  public onContextMenu(event: MouseEvent, item: PerfDataEntry): void {

    this.contextMenuPosition.x = (event.clientX - this._elRef.nativeElement.getBoundingClientRect().left + this._elRef.nativeElement.offsetLeft) + 'px';
    this.contextMenuPosition.y = (event.clientY - this._elRef.nativeElement.getBoundingClientRect().top  + this._elRef.nativeElement.offsetTop) + 'px';

    this.selectedRow = item;

    this.contextMenuTrigger.menuData = { item };
    this.contextMenuTrigger.menu.focusFirstItem('mouse');
    this.contextMenuTrigger.openMenu();
  }

  public onCopy(item: PerfDataEntry): void {
    let data = this.displayedColumns.join(',') + '\n';

    for (let i = 0; i < this.displayedColumns.length; i++) {
      const key = this.displayedColumns[i];

      if (i > 0) data += ',';
      data += item.hasOwnProperty(key) ? item[key] : '';
    }

    data += '\n';

    navigator.clipboard
      .writeText(data)
      .then(() => this.openSnackBar('Data copied to clipboard successfully', 'OK'))
      .catch(() => {
        console.error('Unable to copy text');
      });
  }

  public async onAddToVault(model: any): Promise<void> {
    const { host, project, runid } = this.run;
    Object.assign(model, { name: null, host, project, runid });
    this.selectedRow['vault'] = true;

    const sub = this._vaultService.add(this.teamName, model).subscribe(({ message, vaultItem }) => {
      sub.unsubscribe();
      this.vaults.push(vaultItem);
      this.openSnackBar(`${message}`, 'OK');
    });
  }

  public openSnackBar(message: string, action: string): void {
    const durationInSeconds = 1;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition,
      verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  public addTag(event: MatChipInputEvent): void {
    if (!event.value) return;

    this.tags.add(event.value);
    event.chipInput!.clear();
    this.updateTags();
  }

  public removeTag(keyword: string): void {
    this.tags.delete(keyword);
    this.updateTags();
  }

  public async updateTags(): Promise<void> {
    const tags = [...this.tags].join(',');
    await this._perf.updateTagsAsync(this.teamName, this.run.project, this.run.runid, tags);

    this.onTagsUpdated.emit({ project: this.run.project, runid: this.run.runid, tags: tags });
  }

  public getVaultName(data: PerfDataEntry): string {
    const { project, runid } = this.run;
    const { epoch } = data;

    if (this.vaults && this.vaults.length > 0) {
      const vault = this.vaults.find((vault) => vault.project === project && vault.runid === runid && vault.epoch === epoch);
      return vault?.name ?? 'Unknown';
    }
  }

  public vaultButtonDisabled(data: PerfDataEntry): boolean {
    return this.getVaultName(data) === 'Unknown';
  }

  public $clickVaultName($ev: MouseEvent, data: PerfDataEntry): void {
    $ev.stopPropagation();
    $ev.preventDefault();
    // this._router.navigate(['/', this.teamName, 'vault-detail', this.getVaultName(data)]);
    const vaultName = this.getVaultName(data);
    this.onVaultItemClick.emit({vaultName});
  }

  public $changePollingRate(newRate: number): void {
    if (this._pollingTimer) clearInterval(this._pollingTimer);
    this._pollingTimer = setInterval(this.fetchAll.bind(this), newRate) as unknown as number;
  }

  public async onChangePage(event: PageEvent){
    this.pageInfo.currentPage = event.pageIndex
    await this.fetchRuns();
  }

  public updatePaginator() {
    this.paginator.length = this.pageInfo.total;
    this.paginator.pageSize = this.pageInfo.pageSize;
    this.paginator.pageIndex = this.pageInfo.currentPage;
  }

  public async stop(): Promise<void> {
    var run: Run = this.run;
    const sub = this._experimentsService.stop(this.teamName, run.project, run.runid).subscribe((results: any) => {
      sub.unsubscribe();
      this.warn(`Stopped ${this.teamName}/${run.project}/${run.runid}.`);
    });
  }

  public async continue(device: string): Promise<void> {
    const run: Run = this.run;
    const sub = this._experimentsService.continue(this.teamName, run.project, run.runid, device).subscribe((results: any) => {
      sub.unsubscribe();
      this.warn(`Resumed ${this.teamName}/${run.project}/${run.runid}.`);
    });
  }

  public warn(message: string): void {
    const action = 'OK';
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  resetDevices() {
    this.devices = ['cpu'];
    if (this.allGPUS.length > 0) {
      const count = this.allGPUS[0]['count'];
      for (let i = 0; i < count; i++) {
        this.devices.push(`gpu${i}`);
      }
    }
  }

}
