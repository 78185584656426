import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { removeAffixes, toTitleCase } from 'src/app/directives/string.util';
import { Host } from 'src/app/models/host';
import { HostsService } from 'src/app/services/hosts.service';
import { RelocateRawParameters } from 'src/app/services/raw.service';
import { TeamsService } from 'src/app/services/teams.service';

export type MoveRawDialogData = {
  raws: any[];
  mode: 'copy' | 'move';
  teamName: string;
};

export type MoveRawDialogReturn = RelocateRawParameters[] | void;

type RawInputData = {
  extension: string;
  rawPath: string;
  baseName: string;
  pristine: boolean;
};

@Component({
  selector: 'app-move-raw-dialog',
  templateUrl: './move-raw-dialog.component.html',
  styleUrls: ['./move-raw-dialog.component.scss']
})
export class MoveRawDialogComponent {
  knownHosts: { [key: string]: Host } = {};
  fullRaws: (RelocateRawParameters & RawInputData)[] = [];
  loading: boolean = true;
  titleVerb: string;
  buttonVerb: string;

  public get allPristine() {
    return this.fullRaws.reduce<boolean>((allPristine, raw) => allPristine && raw.pristine, true);
  }

  constructor(
    private hostService: HostsService,
    private teamsService: TeamsService,
    public dialogRef: MatDialogRef<MoveRawDialogComponent, MoveRawDialogReturn>,
    @Inject(MAT_DIALOG_DATA) public data: MoveRawDialogData
  ) {
    (<any>window).moveRaw = this;

    const { mode, raws, teamName } = data;
    this.titleVerb = mode === 'copy' ? 'Copying' : 'Moving';
    this.buttonVerb = toTitleCase(mode);

    this.hostService.loadAsync(teamName).then((hosts) => {
      this.knownHosts = Object.fromEntries(hosts.map((host) => [host.name, host]));
      this.fullRaws.forEach((raw) => {
        if (raw.hostName in this.knownHosts) if (this.knownHosts[raw.hostName].raw) raw.rawPath = this.knownHosts[raw.hostName].raw;
        raw.src = removeAffixes(raw.src, raw.rawPath, raw.extension);
        raw.dst = raw.src;
      });
      this.loading = false;
    });

    this.teamsService.getConfigAsync(teamName).then((teamConfig: any) => {

      raws.forEach(({ fullPath: src, host: hostName, fileName: baseName }) => {
        this.fullRaws.push({
          src,
          dst: src,
          baseName,
          extension: /(?:\.([^./]+))?$/.exec(src)[0],
          hostName,
          rawPath: teamConfig.path.raw,
          pristine: true
        });
      });
    });
  }

  $changeRawPath($ev: Event, raw: RelocateRawParameters & RawInputData): void {
    raw.dst = (<HTMLInputElement>$ev.target).value;
    raw.pristine = raw.src === raw.dst;
  }

  $clickAction(): void {


    this.dialogRef.close(
      this.fullRaws
        .filter((raw) => !raw.pristine)
        .map<RelocateRawParameters>(({ src, dst, hostName, rawPath, extension }) => ({
          src,
          dst,
          hostName
        }))
    );
  }

  $clickClose(): void {
    this.dialogRef.close();
  }
}
