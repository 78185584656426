// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../node_modules/prismjs/themes/prism-tomorrow.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../node_modules/prismjs/plugins/toolbar/prism-toolbar.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pre {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/code-view/code-view.component.scss"],"names":[],"mappings":"AAIA;EACE,oBAAA;AAAF","sourcesContent":["@import 'prismjs/themes/prism-tomorrow.min.css';\n@import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';\n@import 'prismjs/plugins/toolbar/prism-toolbar.min.css';\n\npre {\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
