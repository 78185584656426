import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface PerfDataEntry {
  epoch: number;
  analysis: string;
  msg: string;
  time: string;
  elapsed: number;
  loss: number;
  accuracy: number;
  precision: number;
  recall: number;
  auc: number;
  val_loss: number;
  val_accuracy: number;
  val_precision: number;
  val_recall: number;
  val_auc: number;
  saved: number;
  vault: boolean;
}

export interface PerfData {
  team: string;
  project: string;
  run: number;
  data: PerfDataEntry[];
  tags: string;
  gpu?: number;
}

export interface PerfPagedData extends PerfData {
  total: number;
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

@Injectable({ providedIn: 'root' })
export class PerfService {
  private _collection$: Subject<PerfData> = new Subject();
  get collection$() {
    return this._collection$.asObservable();
  }

  constructor(private http: HttpClient) {}

  public load(teamName: string, projectName: string, runid: number): Observable<PerfData> {
    if (!teamName || !projectName || !runid) {
      return this._collection$.asObservable();
    }
    this.http.get(`${ConfigService.apiUrl}/perf/${teamName}/${projectName}/${runid}`).subscribe((response: any) => {
      if (response) {
        const data = response;
        const results = { team: teamName, project: projectName, run: runid, data: data.perf, tags: data.tags };
        this._collection$.next(results);
      }
    });

    return this._collection$.asObservable();
  }

  public loadAsync(teamName: string, projectName: string, runid: number): Promise<PerfData> {
    return new Promise((resolve, reject) => {
      const sub = this.http.get(`${ConfigService.apiUrl}/perf/${teamName}/${projectName}/${runid}`).subscribe((response: any) => {
        sub.unsubscribe();
        if (response) {
          const { tags, perf: data } = response;
          const results = { team: teamName, project: projectName, run: runid, data, tags };
          resolve(results);
        } else {
          reject();
        }
      });
    });
  }

  public async pageAsync(
    teamName: string,
    projectName: string,
    runid: number,
    pageSize: number,
    pageNum: number,
    sortColumn: string,
    sortOrder: string
  ) {

    const response = await lastValueFrom<any>(this.http.get(`${ConfigService.apiUrl}/perf/paged/${teamName}/${projectName}/${runid}/${pageSize}/${pageNum}/${sortColumn}/${sortOrder}`));
    if (response) {
      return {
        team: teamName,
        project: projectName,
        run: runid,
        data: response.perf,
        tags: response.tags,
        total: response.total,
        totalPages: response.totalPages,
        currentPage: response.currentPage,
        hasNextPage: response.hasNextPage,
        hasPrevPage: response.hasPrevPage
      }
    }

    return {
      team: teamName,
      project: projectName,
      run: runid,
      data: [],
      tags: [],
      total: 0,
      totalPages: 0,
      currentPage: 0,
      hasNextPage: false,
      hasPrevPage: false
    }



  }



  public getRuns(teamName: string, projectName: string, runs: number[]) {
    return this.http.post(`${ConfigService.apiUrl}/perf/${teamName}/${projectName}`, { runs });
  }

  public async updateTagsAsync(teamName: string, project: string, runid: number, tags: string) {
    return lastValueFrom(this.http.put(`${ConfigService.apiUrl}/perf/tags/${teamName}`, { project, runid, tags }));
  }
}
