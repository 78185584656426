import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) {}

  public chat(team: string, username: string, msg: string, sessionId, project: string) {
    return this.http.post(`${ConfigService.chatUrl}/${sessionId}/chat`, { team, username, msg, project });
  }

  public history(sessionId: string) {
    return this.http.get(`${ConfigService.chatUrl}/${sessionId}/history`)
  }

}
