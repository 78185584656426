import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { IdentityService } from 'src/app/services/identity.service';
import { ProjectsService } from 'src/app/services/projects.service';
import {
  CursorModifier,
  DataPointSelectionChangedArgs,
  DataPointSelectionModifier,
  DefaultPaletteProvider,
  ELabelPlacement,
  EllipsePointMarker,
  ESelectionMode,
  EStrokePaletteMode,
  FastLineRenderableSeries,
  FastMountainRenderableSeries,
  HorizontalLineAnnotation,
  IPointMetadata,
  IThemeProvider,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  parseColorToUIntArgb,
  RubberBandXyZoomModifier,
  SciChartOverview,
  SciChartSurface,
  SweepAnimation,
  TModifierKeys,
  TPointMarkerArgb,
  TSciChart,
  VerticalLineAnnotation,
  VisibleRangeChangedArgs,
  XAxisDragModifier,
  XyDataSeries,
  YAxisDragModifier,
  ZoomExtentsModifier,
  ZoomPanModifier
} from 'scichart';
import { chunkedMinMax } from 'src/app/directives/iterable.util';
import { SettingsService } from 'src/app/services/settings.service';
import { timeout } from 'src/app/directives/timeout.util';
import { MatDialog } from '@angular/material/dialog';
import { isNil } from 'lodash';
import { SchemasService } from 'src/app/services/schemas.service';
import { Schema } from 'src/app/models/schema';


enum PacketDisposition {
  Highlighted = 0,
  Normal = 1
}

export class PacketDispositionPaletteProvider extends DefaultPaletteProvider {
  private override: (data?: PacketDisposition) => TPointMarkerArgb | undefined;

  constructor(highlightColorStroke: string, highlightColorFill: string) {
    super();

    this.strokePaletteMode = EStrokePaletteMode.GRADIENT;
    this.override = (data?) =>
      [
        {
          stroke: parseColorToUIntArgb(highlightColorStroke),
          fill: parseColorToUIntArgb(highlightColorFill)
        },
        undefined
      ][data ?? PacketDisposition.Normal];
  }

  overridePointMarkerArgb = (
    _xValue: number,
    _yValue: number,
    _index: number,
    _opacity?: number,
    metadata?: IPointMetadata & { data: PacketDisposition }
  ) => this.override(metadata?.data);
}


type PositionedExplorationData = {
  index: number;
  timestamp: number;
  data: ExplorationData;
};

export interface DialogData {
  value: object;
}

type ExplorationData = {
  'Date-Time': { [key: number]: number };
  Price: { [key: number]: number };
  'Seq. No': { [key: number]: number };
  Volume: { [key: number]: number };
  index: { [key: number]: number };
  Qualifiers: { [key:number]: string };
  session: { [key: number]: number };
  timestamp: { [key: number]: number };
};

@Component({
  selector: 'app-explore-chart',
  templateUrl: './explore-chart.component.html',
  styleUrls: ['./explore-chart.component.scss']
})
export class ExploreChartComponent implements OnInit, OnDestroy {
  @Output() newPosition = new EventEmitter<{ index: number; timestamp: string }>();
  @Output() onPacketSelect = new EventEmitter<{ packetContent: any }>();

  public editorOptions = {
    theme: 'vs-light',
    language: 'json',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    },
    scrollbar: {
      vertical: 'hidden'
    },
    overviewRulerBorder: false,
    readonly: true
  };

  private _subs = new SubscriptionContainer();
  private _horizontalAnnotations = [];
  private _verticalAnnotations = [];
  private _homing = false;
  private _aperture = 10_000;
  private _newRange: [number, number] | undefined;
  private _rangeChangeDebounce: number | undefined;

  public indexUpdate = new Subject<string>();
  public selectedDatasource: string = "Current Project"
  public schemas: Schema[] = [];

  public get schema() : string {
    return this.selectedDatasource == 'Current Project' ? null : this.selectedDatasource;
  }

  public tickData = null;
  public jsonData: string = null;
  public imageData = null;
  public index: number;
  public home: [NumberRange, NumberRange];
  public sampleCount: number;
  public sampleTimestamp: string;
  public indexSubject = new Subject<number>();
  public xAxis: NumericAxis;
  public yAxis: NumericAxis;


  public get displayedSampleLength() : number {
    return this.dataSeries?.count();
  }
  public isLoading: boolean = false;

  public chart: SciChartSurface | undefined = undefined;
  public overview: SciChartOverview | undefined = undefined;
  public wasm: TSciChart | undefined = undefined;
  public modifiers = {
    mouseWheelZoom: new MouseWheelZoomModifier(),
    zoomPan: new ZoomPanModifier(),
    zoomExtents: new ZoomExtentsModifier(),
    xAxisDrag: new XAxisDragModifier(),
    yAxisDrag: new YAxisDragModifier(),
    rubberBandXyZoom: new RubberBandXyZoomModifier(),
    cursor: new CursorModifier(),
    dataPoint: new DataPointSelectionModifier()
  };
  public dataSeries: XyDataSeries;

  public get teamName() {
    return this._identity.me.selectedTeamName;
  }

  public get projectName() {
    return this._identity.me.selectedProjectName;
  }

  public get mouseMode() {
    if (this.modifiers.rubberBandXyZoom.isEnabled) return 'rubberband';
    return 'pan';
  }

  public get hasCursor() {
    return this.modifiers.cursor.isEnabled;
  }

  private async _getDataByRange(range: [number, number]): Promise<ExplorationData> {
    const data = await this._projectsService.data(this.teamName, this.projectName, ...range, this.schema);
    if (isNil(data)) throw { msg: 'nil data', cause: { range } };
    if ('error' in data) throw { msg: 'nil data', cause: data.error };
    return data;
  }

  private async _getDataByIndex(index?: number): Promise<PositionedExplorationData> {
    index ??= this.index;

    const data = await this._getDataByRange([index - this._aperture, index + this._aperture]);
    const timestamp = data?.timestamp ? data?.timestamp[this._aperture] / 1000000 : null;
    return { data, index, timestamp };
  }

  private async _getDataByTimestamp(timestamp: number): Promise<PositionedExplorationData> {
    return {
      ...(await this._projectsService.dataByDate(this.teamName, this.projectName, timestamp, this._aperture, this.schema)),
      timestamp: timestamp * 1000
    };
  }

  private async _getMoreData(): Promise<void> {
    if (!this._newRange) return;

    this.isLoading = true;
    const [nMin, nMax] = this._newRange;
    const data = await this._getDataByRange([nMin, nMax]);

    Object.assign(this, {
      _newRange: undefined,
      _rangeChangeDebounce: undefined
    });

    this.dataSeries.clear();

    const xValues = Object.keys(data.index).map((ind) => parseInt(ind));
    const yValues = Object.values(data.Price);
    const metadata = Object.values(data.Qualifiers).map(x => { return { value: x, data: PacketDisposition.Normal, isSelected: false }});

    this.xValues = xValues;
    this.yValues = yValues;
    this.metadata = metadata;

    this.dataSeries.appendRange(xValues, yValues, metadata);
    this.updateSearch();
    this.isLoading = false;
  }

  private get _theme(): IThemeProvider {
    return this._settingsService.getChartThemeProvider();
  }

  constructor(
    private readonly _identity: IdentityService,
    private readonly _projectsService: ProjectsService,
    private readonly _settingsService: SettingsService,
    public readonly dialog: MatDialog,
    private schemasService: SchemasService
  ) {
    (<any>window).exploreChart = this;

    this.indexUpdate.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (value) => {
      if (!value) return;

      this.index = +value;
      this.sampleTimestamp = null;
      await this.loadData();
    });
  }

  public async ngOnInit(): Promise<void> {
    await Promise.all([this.loadIndices(), this.initChart()]);

    this._subs.add = this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    this._subs.add = this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));

    this._subs.add = this.indexSubject.pipe(debounceTime(500)).subscribe(this.setRandomIndex.bind(this));
    this._subs.add = this._identity.projectChange$.subscribe(async (data) => {
      if(!this.schema) {
        console.log(data)
        await Promise.all([this.loadIndices(), this.initChart()]);
        await this.loadData();

      }
    });

    this.schemas = await this.schemasService.loadAsync(this.teamName) as Schema[];

  }

  public ngOnDestroy(): void {
    if (this.chart) this.chart.delete();
    if (this.overview) this.overview.delete();
    this._subs.dispose();
  }

  public modifierKeys: TModifierKeys;
  public chartInitialized = false;
  public async initChart(): Promise<void> {
    if(this.chartInitialized) {
      return;
    }
    const { sciChartSurface: chart, wasmContext: wasm } = await SciChartSurface.create('explore-chart', {
      theme: this._theme,
      disableAspect: true
    });

    const growBy = new NumberRange(0.1, 0.1);
    this.xAxis = new NumericAxis(wasm, { growBy, labelPrecision: 0 });
    this.yAxis = new NumericAxis(wasm, { growBy, labelPrefix: '$', labelPrecision: 2 });
    chart.xAxes.add(this.xAxis);
    chart.yAxes.add(this.yAxis);

    this.modifiers.rubberBandXyZoom.isEnabled = false;
    this.modifiers.cursor.isEnabled = true;
    this.modifiers.zoomPan.isEnabled = true;
    this.modifiers.dataPoint.allowDragSelect = false;
    (this.modifiers.dataPoint.getSelectionMode = (modifierKeys, isAreaSelection) => {
      this.modifierKeys = modifierKeys;
      return ESelectionMode.Replace;
    }),
    this.modifiers.dataPoint.selectionChanged.subscribe( (args) => {
      if (!this.modifierKeys.shiftKey) {
        return;
      }
      this.$dataPointSelected(args);
    });
    chart.chartModifiers.add(...Object.values(this.modifiers));

    this.xAxis.visibleRangeChanged.subscribe(this.$visibleRangeChanged.bind(this));

    this.dataSeries = new XyDataSeries(wasm, {
      containsNaN: false,
      isSorted: true
    });

    chart.renderableSeries.add(
      new FastLineRenderableSeries(wasm, {
        stroke: '#2196F3',
        strokeThickness: 3,
        pointMarker: new EllipsePointMarker(wasm, {
          width: 8,
          height: 8,
          strokeThickness: 2,
          stroke: 'SteelBlue',
          fill: 'LightSteelBlue'
        }),
        dataSeries: this.dataSeries,
        paletteProvider: new PacketDispositionPaletteProvider('#FFEB3B', '#f5f4e9'),
        animation: new SweepAnimation({ duration: 300, fadeEffect: true })
      })
    );

    const overview = await SciChartOverview.create(chart, 'explore-chart-overview', {
      theme: this._theme,
      transformRenderableSeries: ({ dataSeries }) =>
        new FastMountainRenderableSeries(wasm, {
          dataSeries
        })
    });

    Object.assign(this, { chart, wasm, overview });
    this.chartInitialized = true;
  }

  public async loadIndices(): Promise<void> {
    this.sampleCount = await this._projectsService.count(this.teamName, this.projectName, this.schema);
    await this.setRandomIndex();
  }

  public async getData(options?: { index: number } | { timestamp: number } | { range: [number, number] }) {
    if (isNil(this.sampleCount) || this.sampleCount === 0) {
      return;
    }
    if (isNil(options)) return await this._getDataByIndex();
    if ('index' in options) return await this._getDataByIndex(options.index);
    if ('timestamp' in options) return await this._getDataByTimestamp(options.timestamp);
  }

  public mapData(data: ExplorationData, index?: number) {
    index ??= this.index;

    if (data === null) throw { msg: 'nil data', cause: { data, index } };
    if (!Object.keys(data).includes('Price')) throw { msg: "Data error: missing 'Price'", cause: { data } };

    const keys = Object.keys(data.Price).map(x => parseInt(x));
    const xValues = keys;
    const yValues = keys.map((x) => data.Price[x]);
    const metadata = keys.map((x) => { return {
      value: data.Qualifiers[x],
      data: PacketDisposition.Normal,
      isSelected: false
    }});

    const [xMin, xMax] = chunkedMinMax(xValues);
    const [yMin, yMax] = chunkedMinMax(yValues);
    const yDiff = Math.abs(yMax - yMin) / 3;
    const home: [NumberRange, NumberRange] = [new NumberRange(xMin + 500, xMax - 500), new NumberRange(yMin - yDiff, yMax + yDiff)];

    return { xValues, yValues, home, metadata };
  }

  private xValues;
  private yValues;
  private metadata;
  public async loadData(options?: { index: number } | { timestamp: number }): Promise<void> {
    const retdata = await this.getData(options);
    if (!retdata) {
      this.tickData = null;
      return;
    }
    const { data, index, timestamp: sampleTimestamp } = retdata;

    this.index = index;
    this.sampleTimestamp = sampleTimestamp ? new Date(sampleTimestamp).toLocaleString() : null;

    if (Object.keys(data).includes('Price')) {
      this.tickData = data;
      const { xValues, yValues, home, metadata } = this.mapData(data);
      this.xValues = xValues;
      this.yValues = yValues;
      this.metadata = metadata;

      this.home = home;
      this.dataSeries.clear();
      this.dataSeries.appendRange(xValues, yValues, metadata);
      this.setIndexAnnotation();
      this.homeView();

      this.newPosition.emit({ index: this.index, timestamp: this.sampleTimestamp });
    }
    if (Object.keys(data).includes('json')) {
      this.jsonData = JSON.stringify(data['json'], null, '\t');
    }
    if (Object.keys(data).includes('image')) {
      this.imageData = data['image'];
    }
  }

  search: string;
  updateSearch() {
    this.updateMetadata(this.search);
  }

  updateMetadata(value:string) {
    if(!this.metadata) {
      return;
    }
    this.metadata.forEach(m => {
      if(!value) {
        m.data = PacketDisposition.Normal
      }
      else {
        m.data = m.value.includes(value) ? PacketDisposition.Highlighted : PacketDisposition.Normal
      }
    });

    this.dataSeries.clear();
    this.dataSeries.appendRange(this.xValues, this.yValues, this.metadata);
  }

  getImagePath() {
    return 'data:image/jpg;base64,' + this.imageData;
  }

  public async setRandomIndex(): Promise<void> {
    this.index = Math.floor(Math.random() * this.sampleCount);
    this.sampleTimestamp = null;
    await this.loadData();
    this.updateSearch();
  }

  public removeAnnotation(id: string): void {
    const oldIndex = this.chart.annotations.getById(id);
    this.chart.annotations.remove(oldIndex);
  }

  public setIndexAnnotation(index?: number): void {
    this.removeAnnotation('index');
    this.chart.annotations.add(
      new VerticalLineAnnotation({
        id: 'index',
        stroke: '#775DD0',
        strokeThickness: 3,
        x1: index ?? this.index,
        labelValue: 'Index',
        showLabel: true,
        labelPlacement: ELabelPlacement.TopRight
      })
    );
  }

  public setFeatureAnnotations(instructions: any[]): void {
    const horizontal = instructions.filter((x) => x.line.orientation === 'horizontal');
    const vertical = instructions.filter((x) => x.line.orientation === 'vertical');

    this._horizontalAnnotations.forEach(this.removeAnnotation.bind(this));
    this._verticalAnnotations.forEach(this.removeAnnotation.bind(this));

    this._horizontalAnnotations = horizontal.map(({ line }, index) => {
      const id = `horizontal-${index}`;

      this.chart.annotations.add(
        new HorizontalLineAnnotation({
          id,
          stroke: line.color,
          strokeThickness: 3,
          y1: line.value,
          labelValue: line.label,
          showLabel: true,
          labelPlacement: ELabelPlacement.TopRight
        })
      );

      return id;
    });

    this._verticalAnnotations = vertical.map(({ line }, index) => {
      const id = `vertical-${index}`;

      this.chart.annotations.add(
        new VerticalLineAnnotation({
          id,
          stroke: line.color,
          strokeThickness: 3,
          x1: line.value,
          labelValue: line.label,
          showLabel: true,
          labelPlacement: ELabelPlacement.TopRight
        })
      );

      return id;
    });
  }

  public async maximizeView(): Promise<void> {
    this._homing = true;

    this.chart.zoomExtents(300);
    await timeout(350);

    this._homing = false;
  }

  public async homeView(animate: boolean = true): Promise<void> {
    if (!this.home) return;
    this._homing = true;
    const [xHome, yHome] = this.home;
    const duration = animate ? 300 : 0;

    this.xAxis.animateVisibleRange(xHome, duration);
    this.yAxis.animateVisibleRange(yHome, duration);
    await timeout(duration + 50);
    this._homing = false;
  }

  public async $dateTimeSelect(timestamp: number): Promise<void> {
    await this.loadData({ timestamp });
    this.updateSearch();
  }

  public async $indexShuffle(): Promise<void> {
    this.setRandomIndex();
  }

  public async $visibleRangeChanged({ visibleRange }: VisibleRangeChangedArgs) {
    if (this._homing) return;

    const destructureRange = (range: NumberRange) => [range.min, range.max].map(Math.trunc);
    // Bail if not out-of-bounds
    const [xMin, xMax] = destructureRange(this.dataSeries.xRange);
    const [vMin, vMax] = destructureRange(visibleRange);
    if (vMin >= xMin && vMax <= xMax) return;

    // Set pending new range
    const [pMin, pMax] = this._newRange ?? [Infinity, -Infinity];
    this._newRange = [Math.min(xMin, vMin, pMin), Math.max(xMax, vMax, pMax)];

    // Manually debounce when actively changing
    if (this._rangeChangeDebounce) window.clearTimeout(this._rangeChangeDebounce);
    this._rangeChangeDebounce = window.setTimeout(this._getMoreData.bind(this), 250);
  }

  public async $dataPointSelected({ selectedDataPoints: points }: DataPointSelectionChangedArgs): Promise<void> {
    if (points.length !== 1) console.warn(`Multiple Data Points Selected: ${points.length}`);

    if (points.length === 1) {
      const [datapoint] = points;
      const selectedIndex = datapoint.xValue;
      const data = await this._getDataByRange([selectedIndex, selectedIndex + 1]);

      const propertyNames = Object.keys(data);
      var obj = {};
      propertyNames.forEach(x => {
        obj[x] = data[x][selectedIndex];
      })

      const packetContent = JSON.stringify(obj, null, 4);
      console.log(data, packetContent);
      this.onPacketSelect.emit({ packetContent });
    }
  }

  public $changeTheme(): void {
    if (this.chart) this.chart.applyTheme(this._theme);
    if (this.overview) this.overview.applyTheme(this._theme);
  }

  public $clickMaximize(): void {
    this.maximizeView();
  }

  public $clickHome(): void {
    this.homeView();
  }

  public $clickToggleRubberband(): void {
    this.modifiers.rubberBandXyZoom.isEnabled = !this.modifiers.rubberBandXyZoom.isEnabled;
    this.modifiers.zoomPan.isEnabled = !this.modifiers.rubberBandXyZoom.isEnabled;
  }

  public $clickToggleCursor(): void {
    this.modifiers.cursor.isEnabled = !this.modifiers.cursor.isEnabled;
  }

  public async onDatasourceSelected(event) {
    await Promise.all([this.loadIndices(), this.initChart()]);
    await this.loadData();
  }
}
