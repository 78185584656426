import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MetaParam } from 'src/app/models/meta';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() param: MetaParam;
  @Input() form: FormGroup;
  @Input() chunkDescription: boolean = false;
  @Output() change = new EventEmitter<void>();

  public controlId: string;

  ngOnInit(): void {
    this.controlId = `${this.param.name}_${new Date().getTime().toString()}`;

    const rawValue = this.form.get(this.param.name).getRawValue();
    if (!this.param?.options?.includes(rawValue)) {
      console.warn(`Value "${rawValue}" not found in options`, this.param.options);
    }
  }

  onSelect(value: any): void {
    this.form.get(this.param.name).setValue(value);
    this.change.emit();
  }
}
