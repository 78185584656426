import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataBuild } from 'src/app/models/data-build';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { Host } from 'src/app/models/host';
import { HostsService } from 'src/app/services/hosts.service';
import { IdentityService } from 'src/app/services/identity.service';
import { TeamsService } from 'src/app/services/teams.service';
import { MenuService } from 'src/app/services/menu.service';
import { OmnibarComponent } from '../../omnibar/omnibar.component';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { debounce } from 'src/app/directives/debounce.decorator';

@Component({
  selector: 'app-builds',
  templateUrl: './builds.component.html',
  styleUrls: ['./builds.component.scss']
})
export class BuildsComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(OmnibarComponent) omnibar: OmnibarComponent;

  @Input() teamName: string;
  @Input() isWidget: boolean = false;
  @Output() onBuildClick = new EventEmitter<{ rdsName: string; runid: number; user: string; isArchived: boolean }>();

  private subSort: any = null;
  public pageSize = 10;

  public isLoading: boolean = true;
  public availableColumns: string[] = [
    'select',
    'isRunning',
    'status',
    'rdsName',
    'runid',
    'host',
    'user',
    'startTime',
    'endTime',
    'lastModified',
    'duration',
    'errors',
    'tags'
  ];
  public displayedColumns: string[] = [
    'select',
    'isRunning',
    'status',
    'rdsName',
    'runid',
    'user',
    'startTime',
    'duration',
    'errors',
    'tags'
  ];
  public dataSource = new MatTableDataSource<DataBuild>([]);
  public selection = new SelectionModel<DataBuild>(true, []);
  public originalData: DataBuild[] = [];
  private knownHosts: { [key: string]: Host } = {};
  private _subs = new SubscriptionContainer();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataBuildsService: DataBuildsService,
    private identity: IdentityService,
    private hostsService: HostsService,
    private teamsService: TeamsService,
    private menuService: MenuService,
    private _socketService: SocketService,
    private readonly _element: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    if (window.innerHeight > 890) {
      this.pageSize = 20;
    }

    if (this.isWidget) {
      this.load(this.teamName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team);
      });
    }

    this._socketService.joinRoom('builds');
    this._subs.add = this._socketService.subscribeToRoomEvents('builds', (data) => {
      console.log(data);
      this.load(this.teamName);
    });

    (<any>window).builds = this;
    this.menuService.delayedSetActive('builds');
  }

  ngOnDestroy(): void {
    this._socketService.leaveRoom('builds');
    this._subs.dispose();
  }

  onResize() {
    this.optimizePageSize()
  }

  optimizePageSize() {
    if (this.isWidget) {
      const parentHeight: number = this._element.nativeElement.offsetHeight;
      var x = parentHeight;

      this.pageSize = Math.round( (0.024 * x) - 5);
      if (this.paginator) {
        this.paginator._changePageSize(this.pageSize);
      }
    } else {
      var x = window.innerHeight;
      var blocks = Math.round(0.00493601 * x - 1.3);
      this.pageSize = blocks * 5;
      this.paginator._changePageSize(this.pageSize);
    }
  }

  @debounce(2500)
  load(teamName: string) {
    this.teamName = teamName;
    const sub = this.dataBuildsService.load(teamName).subscribe((results: DataBuild[]) => {
      sub.unsubscribe();
      this.isLoading = false;
      const timestampColumns = ['startTime', 'lastModified', 'endTime'];
      timestampColumns.forEach((col) => {
        results.forEach((row) => {
          if (row[col]) {
            row[col] = new Date(row[col]);
          }
        });
      });

      this.originalData = results;
      this.dataSource.data = this.originalData.filter((x) => !x.isArchived);
      this.loadHosts(results.map((e) => e.host));
      setTimeout(() => {
        this.loadView(this.activatedRoute.snapshot.queryParams);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if (!this.subSort) {
          this.subSort = this.sort?.sortChange.subscribe((value) => {
            this.updateRoute();
          });
        }
      }, 0);
      this.optimizePageSize();
    });
  }

  async loadHosts(hosts: string[]) {
    const uniqueHosts = [...new Set(hosts)];
    await Promise.all(
      uniqueHosts.map(async (hostName: string) => (this.knownHosts[hostName] = await this.hostsService.getAsync(this.teamName, hostName)))
    );
  }

  applyFilter(filterValue: string) {
    if (filterValue.length == 0) this.dataSource.data = this.originalData.filter((x) => !x.isArchived);
    else this.dataSource.data = this.originalData;

    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();

    this.updateRoute();
  }

  openDiff() {
    this.teamsService.getConfigAsync(this.teamName).then((config) => {
      const encode = (x: DataBuild) => encodeURI(`${config.path.logs}/${x.runid}/${x.rdsName}.json`);
      const left = encode(this.selection.selected[0]);
      const right = encode(this.selection.selected[1]);
      window.open(this.router.serializeUrl(this.router.createUrlTree([this.teamName, 'model-diff', left, right])), '_blank');
    });
  }

  updateRoute() {
    const selected = this.selection.selected.map((x) => `${x.host}:${x.rdsName}:${x.runid}`).join(',');
    const queryParams: Params = {};
    if (selected.length > 0) {
      queryParams.selected = selected;
    }

    if (this.sort.active && this.sort.direction && (this.sort.active !== 'lastModified' || this.sort.direction !== 'desc')) {
      queryParams.sort = `${this.sort.active},${this.sort.direction}`;
    }

    if (this.omnibar.value) {
      queryParams.search = this.omnibar.value;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams });
  }

  loadView(params) {
    if (params.search) {
      this.omnibar.value = params.search;
      this.applyFilter(this.omnibar.value);
    }

    if (params.selected) {
      const selected = params.selected?.split(',');
      selected.forEach((selected) => {
        const [host, rdsName, runid] = selected.split(':');
        const row = this.dataSource.data.find((x) => x.host === host && x.rdsName === rdsName && x.runid === +runid);
        if (row && !this.selection.isSelected(row)) {
          this.selection.toggle(row);
        }
      });
    }

    if (params.sort) {
      const [column, direction] = params.sort.split(',').map((x) => x.trim());
      if (column && direction) this.sort?.sort({ id: column, start: direction } as MatSortable);
    }
  }

  rowClick(row) {
    if (this.isWidget) {
      this.onBuildClick.emit({ rdsName: row.rdsName, runid: row.runid, user: row.user, isArchived: row.isArchived });
    } else {
      this.router.navigate([this.teamName, 'data', 'build', row.rdsName, row.runid, row.user, row.isArchived]);
    }
  }

  getRunningStatus(row: DataBuild): 'Complete' | 'Running' | 'Running_Errors' | 'Errors' | 'Cancelled' | 'Unknown' {
    const { status, errors, cancelled, isRunning } = row;
    if (errors && !isRunning) return 'Errors';
    else if (cancelled) return 'Cancelled';
    else if (isRunning && errors) return 'Running_Errors';
    else if (isRunning) return 'Running';
    else if (status === 'complete') return 'Complete';
    else return 'Unknown';
  }

  selectAll() {
    this.dataSource.filteredData.forEach((row: DataBuild) => this.selection.select(row));
  }

  refresh() {
    this.isLoading = true;
    this.load(this.teamName);
  }

  allSelectedCanBeArchived() {
    return this.selection.selected.every((x) => (!x.isArchived && x.cancelled) || !x.isRunning);
  }

  allSelectedCanBeRestored() {
    return this.selection.selected.every((x) => x.isArchived);
  }

  allSelectedCanBeStopped() {
    return this.selection.selected.every((x) => x.isRunning);
  }

  archive() {
    this.setArchive(true);
  }

  restore() {
    this.setArchive(false);
  }

  stop() {
    if (!confirm('Are you sure you want to cancel these builds?')) return;

    this.selection.selected.forEach(async (build) => {
      const sub = this.dataBuildsService.stop(this.teamName, build.rdsName, build.runid.toString()).subscribe(() => {
        sub.unsubscribe();
        build.isRunning = false;
        build.cancelled = true;
      });
    });
  }

  setArchive(archive: boolean) {
    const archivist = (archive ? this.dataBuildsService.archive : this.dataBuildsService.restore).bind(this.dataBuildsService);
    const { selected } = this.selection;
    const { username } = this.identity.me;

    selected.forEach((build) => {
      const sub = archivist(this.teamName, build.runid, username).subscribe(() => {
        sub.unsubscribe();
        build.isArchived = !build.isArchived;
        this.selection.toggle(build);
        this.applyFilter(this.omnibar.value);
      });
    });
  }
}
