// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

.user-input {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-container {
  height: calc(100% - 24px);
}

.content {
  position: relative;
  height: calc(100% - 54px);
}

::ng-deep .user-input .mat-mdc-form-field-subscript-wrapper {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/project-board-widget/project-board-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n\n.user-input {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.chat-container {\n  height: calc(100% - 24px);\n}\n\n.content {\n  position: relative;\n  height: calc(100% - 54px);\n}\n\n::ng-deep .user-input .mat-mdc-form-field-subscript-wrapper {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
